import { useEffect } from "react";
import log from "../api/log";

const logVideo = (video, type) => log.video("information", video.getAttribute("procedure"), video.getAttribute("topicId"), type)

const useLogVideo = (contentRef) => {

	useEffect(() => {
		if (!contentRef.current) return;

		const observer = new MutationObserver((mutations) => {
			if (!contentRef.current) return;

			mutations.forEach((mutation) => {
				if (mutation.type === "childList") {
					const videos = contentRef.current.getElementsByTagName("video");

					for (const video of videos) {
						video.onplay = () => logVideo(video, "start");
						video.onended = () => logVideo(video, "end");
					}
				}
			})
		})

		observer.observe(contentRef.current, { childList: true, subtree: true })

		return () => {

			observer.disconnect();
			
			if (!contentRef.current) return;

			const videos = contentRef.current.getElementsByTagName("video");

			for (const video of videos) {
				video.onplay = null;
				video.onended = null;
			}

		};
	}, [
		contentRef
	]);
};

export default useLogVideo;
