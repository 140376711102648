// landing page categories:
// Leaving this for now, but it does not seem to be used with the new home page
export const EducationCategories = {
  KNEE: "knee",
  HIP: "hip",
  SPINE: "spine",
  SHOULDER: "shoulder",
};

export const education = {
  knee: {
    id: "ace8872",
    title: "Knee Replacement Surgery Overview",
    paragraphs: [
      "Joint replacement involves surgery to replace the ends of bones in a damaged joint. This surgery creates new joint surfaces.",
      "In total knee replacement surgery, the ends of the damaged thigh and lower leg (shin) bones and usually the kneecap are capped with artificial surfaces. These surfaces are lined with metal and plastic. Doctors usually secure parts of the knee joint components to the bones with cement.",
      "Doctors are working on ways to replace just the damaged parts of the knee joint. This is sometimes called partial joint replacement. Unicompartmental replacement is one example of partial knee replacement. It replaces just the inner knee surfaces or the outer knee surfaces, depending on where the damage is. Another partial replacement is called a patellofemoral replacement. In this surgery, the end of the thigh bone is replaced. Then an artificial surface is used to line the back of the kneecap.",
      "Your doctor may use regional anesthesia. This means you can't feel the area of the surgery. You'll have medicine that makes you unaware and lightly asleep. Or your doctor may use general anesthesia. This means you'll be asleep during surgery. Which type of anesthesia you get depends on your doctor and on your overall health. Your doctor might also ask what you prefer.",
      "If you need any major dental work, your doctor may recommend that you have it done before the surgery. Infections can spread from other parts of the body, such as the mouth, to the artificial joint and cause a serious problem. But some experts don't agree that this is likely to happen.",
    ],
  },
  hip: {
    id: "abn1949",
    title: "Hip Replacement: Before Your Surgery",
    paragraphs: [
      "The hip joint allows you to move your leg smoothly. The joint connects the hip bone to the thighbone. Osteoarthritis is the most common reason for hip replacement. With osteoarthritis, the protective surface called cartilage - the stuff that protects and cushions the joint-breaks down. As it breaks down, the bony surfaces of the joint rub against each other, which causes damage and pain.",
      "Hip replacement surgery can help treat that damage and pain by replacing the parts of the hip with new metal and plastic parts.",
    ],
    isVideo: true,
  },
  spine: {
    id: "abu5414",
    title: "Cervical Spinal Fusion: Overview",
    paragraphs: [
      "Cervical spinal fusion is surgery that joins two or more of the vertebrae in your neck. When these bones are joined together, it's called fusion. After the joints are fused, they can no longer move.",
      'During the surgery, the doctor uses bone to make a "bridge" between your vertebrae. This bridge may be strengthened with metal plates and screws. In most cases, the doctor uses bone from another part of your body or bone that has been donated to a bone bank. But sometimes human-made bone is used.',
      "To do the surgery, the doctor makes a cut in either the front or the back of your neck. The cut is called an incision. It leaves a scar that fades with time.",
      "After surgery, you will have a short hospital stay. Your neck will feel stiff or sore. You will get medicine to help with pain.",
      "Most people can go back to work after 4 to 6 weeks. But it may take a few months to get back to your usual activities.",
    ],
  },
  shoulder: {
    id: "abu1283",
    title: "Shoulder Arthroscopic Surgery: Overview",
    paragraphs: [
      "Shoulder arthroscopy is a type of surgery. It lets a doctor repair shoulder problems without making a large cut (incision).",
      "To do this surgery, the doctor puts a lighted tube through small incisions in your shoulder. The tube is called an arthroscope or scope. Next, the doctor puts some surgical tools in the scope to help make any repairs. The incisions will leave scars that usually fade with time.",
      "This type of surgery is used to treat many shoulder problems.",
    ],
  },
};
