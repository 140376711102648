import React, { useRef, useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { NavbarProvider } from "../../../contexts/NavbarProvider";
import Footer from "../Footer/Footer";
import BackToTopButton from "../../../components/BackToTopButton/BackToTopButton";

const Layout = ({
  procedurePage = false,
  footer = false,
  backToTop = false,
}) => {
  const navBarRef = useRef(null);

  const [navBarHeight, setNavBarHeight] = useState(0);

  useEffect(() => {
    if (navBarRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setNavBarHeight(navBarRef.current.offsetHeight);
      });
      resizeObserver.observe(navBarRef.current);
    }
  }, [navBarRef]);

  return (
    <NavbarProvider>
      <CssBaseline>
        <Navbar ref={navBarRef} procedurePage={procedurePage} />
        <main
          style={{
            paddingTop: navBarHeight,
          }}
        >
          <Outlet />
        </main>
        {backToTop && <BackToTopButton />}
        {footer && <Footer />}
      </CssBaseline>
    </NavbarProvider>
  );
};

export default Layout;
