import styled from "@emotion/styled";

export const StyledImage = styled("img")(({ theme, ...props }) => ({
	borderRadius: 6,
	width: props.width ?? "100%",
	height: props.height ?? "100%",
	maxWidth: props.maxWidth ?? "100%",
	maxHeight: props.maxHeight ?? "100%",
	objectFit: props.objectFit ?? "contain",
	objectPosition: props.objectPosition ?? "center 70%",
	...props,

	[theme.breakpoints.down("lg")]: {
		...{ height: props.mdHeight },
		objectFit: "cover",
	},
	[theme.breakpoints.down("sm")]: {
		width: !props.logo || !props.width ? "100%" : props.width,
	},
}));
