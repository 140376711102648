import { procedureItems, procedurePages } from "../data/procedures";

const urlPaths = {
  PROCEDURES: "procedures",
  TOPICS: "topics",
};

const findCategorySlugByTopics = (slugToFind) => {
  let result = null;

  Object.entries(procedurePages).forEach(([, category]) => {
    category.procedures.phases.forEach((phase) => {
      if (phase.slug === slugToFind) {
        result = category.slug;
      }
    });
    if (result) return;
  });

  return result;
};

export const getProcedureIndexFromURL = (pathName) => {
  const splittedPath = pathName.split("/");

  const proceduresUrlSlugIndex = splittedPath.findIndex(
    (sp) => sp === urlPaths.PROCEDURES
  );

  if (proceduresUrlSlugIndex !== -1) {
    return procedureItems.findIndex(
      (pi) => pi.slug === splittedPath[proceduresUrlSlugIndex + 1]
    );
  } else {
    const topicsUrlSlugIndex = splittedPath.findIndex(
      (sp) => sp === urlPaths.TOPICS
    );
    const procedureSlug = findCategorySlugByTopics(splittedPath[topicsUrlSlugIndex + 1]);

    return procedureItems.findIndex((pi) => pi.slug === procedureSlug);
  }
};