import { Grid, Typography } from "@mui/material";
import Image from "../../../components/Image/Image";
import ProceduresImage from "../../../assets/img/procedures.jpeg";
import ProcedureBoxes from "../ProcedureBoxes/ProcedureBoxes";

const Procedures = ({
  title = "",
  procedureItems = [],
  paragraphs = [],
  proceduresImg = ProceduresImage,
  proceduresImgAlt,
  reverseBoxes,
  isGeneral,
}) => {
  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      columnSpacing={1}
      rowGap={3}
      px={[3, 16]}
    >
      <Grid item md={6}>
        <Typography variant="h2"> {title} </Typography>
        {paragraphs.map((text) => (
          <Typography key={text} variant="body1" paragraph>
            {text}
          </Typography>
        ))}
      </Grid>
      <Grid
        item
        container
        sm={12}
        md={6}
        justifyContent={["center", "center", "end"]}
        alignItems="center"
      >
        <Image
          logo
          src={proceduresImg}
          alt={proceduresImgAlt}
          width="auto"
          height={332}
          mdHeight={252}
          maxHeight={600}
          objectFit="cover"
          // objectPosition="right top"
        />
      </Grid>
      <ProcedureBoxes items={procedureItems} isGeneral={isGeneral} />
    </Grid>
  );
};

export default Procedures;
