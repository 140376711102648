import { Box, Typography } from "@mui/material";
import Line from "../components/Line/Line";
import { useEffect } from "react";
import log from "../api/log";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    log.pageView("information", {
      message: "Privacy Policy Page View",
      success: true,
      url: window.location.href,
    });
  }, []);

  return (
    <>
      <Typography variant="h1" marginTop="4px" textAlign={"center"}>
        Privacy Policy
      </Typography>
      <Line />
      <Box
        marginTop="1em"
        paddingX="5%"
        display={"flex"}
        flexDirection={"column"}
        flexWrap={"wrap"}
      >
        <Typography fontStyle="italic" variant="body1margin">
          Last updated: June 12, 2024
        </Typography>
        <Typography fontStyle="italic" variant="body1margin">
          TurningPoint Healthcare Solutions Member Services Privacy Policy
        </Typography>
        <Typography variant="body1margin">
          The purpose of this Member Services Privacy Policy (“Member Privacy
          Policy”) is to describe how TurningPoint Healthcare Solutions LLC and
          its affiliates (“<b>TurningPoint</b>”, “<b>we</b>”, “<b>our</b>” or “
          <b>us</b>”) collect, use and share information about you when you use
          online services that link to this Member Privacy Policy, including but
          not limited to myhealth.tsphealth.com, email, text, and other
          electronic messages between you TurningPoint, and written documents,
          phone calls and other offline activities between you and TurningPoint
          (“Services”).
        </Typography>
        <Typography variant="body1margin">
          Please read this notice carefully to understand how we use, disclose
          and otherwise manage the personal information we collect about you. If
          you do not understand any aspects of this Privacy Policy, please feel
          free to contact us (our contact information is at the end of this
          Privacy Policy). This Privacy Policy is not a contract and does not
          create any contractual rights or obligations.
        </Typography>
        <Typography variant="body1margin">
          We are constantly trying to improve our Services, so we may need to
          change this Privacy Policy from time to time. Unless we say otherwise,
          changes will be effective upon the last updated date at the top of
          this Privacy Policy. Please check this Privacy Policy regularly to
          ensure that you are aware of any changes. Your use of the Services
          after changes to this Privacy Policy means you have accepted the
          changes.
        </Typography>
        <Typography variant="body1margin">
          <b>A Note About Protected Health Information</b>
        </Typography>
        <Typography variant="body1margin">
          TurningPoint provides these Services to you as a member or participant
          in a health plan sponsored or administered by one of our health plan
          customers, or you receive healthcare from one of our affiliated
          professional entities. As a result, we may collect Protected Health
          Information about you that is governed by HIPAA in our capacity as a
          “business associate” to your health plan or our affiliated
          professional entity. Your health plan's or affiliated professional
          entity's Notice of Privacy Practices and our agreements with your
          health plan or our affiliated professional entity will govern the use
          of your Protected Health Information “<b>HIPAA</b>” means Health
          Insurance Portability and Accountability Act of 1996, the Health
          Information Technology for Economic and Clinical Health Act and their
          implementing regulations as amended from time to time. “
          <b>Protected Health Information</b>” is individually identifiable
          health information that is protected by HIPAA and that we receive on
          behalf of Covered Entities. “<b>Covered Entity</b>” refers to a health
          plan, healthcare provider or other entity subject to HIPAA.
        </Typography>
        <Typography variant="body1margin">
          “<b>Business Associate</b>” is an entity that performs functions or
          activities on behalf of, or provides certain services to, a covered
          entity that involves access by the business associate to protected
          health information.
        </Typography>
        <Typography variant="body1margin">
          Under the HIPAA rules and the terms of our agreements with your health
          plan, TurningPoint must appropriately safeguard your protected health
          information and may use or disclose protected health information only
          as permitted or required by its business associate contract or as
          required by law.
        </Typography>
        <Typography variant="body1margin">
          <b>Information We Collect</b>
        </Typography>
        <Typography variant="body1margin">
          <b>Information You Provide to Us.</b> We may collect information you
          provide to us, including PHI, through the Services and offline, for
          example when you create or modify your account, register to use our
          Services, purchase products or services from us, request information
          from us, contact customer support, fill out any form on a Site, or
          otherwise communicate with us. This information may include:
          <br />
          <ul>
            <li>Name</li>
            <li>Address</li>
            <li>Email address</li>
            <li>Telephone number</li>
            <li>Date of birth</li>
            <li>Username and password</li>
            <li>
              Any other information requested or provided through a contact
              form, registration form, email, text or other message with the
              Site.
            </li>
            <li>Insurance information</li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          <b>Information We Collect Automatically.</b> Whenever you interact
          with our Services, we automatically receive and record information
          from your browser or device using cookies or similar technologies.
          This information may include your IP address, geolocation data, device
          identification, the type of browser and/or device you're using to
          access our Services, and the page or feature you requested. To learn
          more about the cookies and other technologies we use, please see our
          Statement on Cookies and Similar Technologies below.
        </Typography>
        <Typography variant="body1margin">
          The information we collect automatically may include personal
          information, or we may maintain it or associate it with personal
          information we collect in other ways or receive from third parties. It
          helps us to improve the Services and to deliver a better and more
          personalized service, including by enabling us to: <br />
          <ul>
            <li>Estimate our audience size and usage patterns.</li>
            <li>
              Store information about your preferences, allowing us to customize
              our Site according to your individual interests.
            </li>
            <li>Speed up your searches.</li>
            <li>Recognize you when you return to our Site.</li>
          </ul>
          We may use this data to customize content for you that we think you
          might like, based on your usage patterns. We may also use it to
          improve the Services – for example, this data can tell us how often
          users use a particular feature of a Service, and we can use that
          knowledge to make the Services more helpful to as many users as
          possible.
        </Typography>
        <Typography variant="body1margin">
          <b>How We Use and Share Your Information</b>
        </Typography>
        <Typography variant="body1margin">
          <b>To Provide Services and Information</b> We use your information to:{" "}
          <ul>
            <li>present our Services, Sites and their contents to you</li>
            <li>
              provide you with information, products, or services that you
              request from us
            </li>
            <li>fulfill any other purpose for which you provide it</li>
            <li>register and service your online account</li>
            <li>provide information that you request from us</li>
            <li>
              send you promotional materials about our products and services, as
              well as new features and offerings
            </li>
            <li>enforce our Terms or Use or other legal rights and remedies</li>
            <li>
              notify you about changes to our Services or any products we offer
              or provide
            </li>
            <li>
              fulfill any other purposes disclosed to you at the time we collect
              your information or pursuant to your consent.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          <b>Vendors and Service Providers.</b> We may provide information to
          third-party vendors and service providers that help us operate and
          manage and deliver products and Services that you use through us.
          These vendors and service providers will have access to your personal
          information in order to provide these services, but when this occurs
          we implement reasonable contractual and technical protections to limit
          their use of that information to helping us provide the service.
        </Typography>
        <Typography variant="body1margin">
          <b>Third-Party Analytics.</b> We may use selected third parties to
          collect data about how you interact with the Services. This
          information may be used to, among other things, improve the
          functionality of our Services.
        </Typography>
        <Typography variant="body1margin">
          <b>With Your Consent.</b> In addition to the sharing described
          elsewhere in this Privacy Policy, we will share personal information
          with your health plan, and when we have consent to do so, other
          companies, organizations or individuals.
        </Typography>
        <Typography variant="body1margin">
          <b>Legal Proceedings.</b> We will share personal information with
          third-party companies, organizations or individuals if we have a
          good-faith belief that access, use, preservation or disclosure of the
          information is reasonably necessary to:
          <ul>
            <li>
              meet any applicable law, regulation, subpoena, legal process or
              enforceable governmental request;
            </li>
            <li>
              enforce applicable Terms, including investigation of potential
              violations;
            </li>
            <li>
              detect, prevent, or otherwise address fraud, security or technical
              issues; or
            </li>
            <li>
              protect against harm to the rights, property or safety of
              TurningPoint, our users, customers or the public as required or
              permitted by law.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          <b>Transfer in the Event of Sale or Change of Control.</b> If the
          ownership of all or substantially all of our business changes or we
          otherwise transfer assets relating to our business or the Site to a
          third party, such as by merger, acquisition, bankruptcy proceeding or
          otherwise, we may transfer or sell your personal information to the
          new owner. In such a case, unless permitted otherwise by applicable
          law, your information would remain subject to the promises made in the
          applicable privacy policy.
        </Typography>
        <Typography variant="body1margin">
          <b>Statement on Cookies and Similar Technologies</b>
        </Typography>
        <Typography variant="body1margin">
          Our Services use cookies and other data collection technologies
          described in this section.
        </Typography>
        <Typography variant="body1margin">
          <b>Cookies.</b> A cookie is a small file placed on your computer's
          hard drive that collects and stores information about your equipment,
          preferences and browsing patterns. We use cookies to analyze web page
          traffic, usage patterns, and to tailor our Services to your individual
          interests. For more information about cookies, visit
          allaboutcookies.org.
        </Typography>
        <Typography variant="body1margin">
          <b>Web Beacons.</b> A web beacon (also referred to as clear gif, pixel
          tag or single-pixel gif) is a transparent graphic image used in tandem
          with cookies that enables us to record a user's actions. We use web
          beacons to count users who have visited those pages, verify system and
          server integrity and for similar statistical measures.
        </Typography>
        <Typography variant="body1margin">
          You can set your Internet browser settings to stop accepting new
          cookies, to receive notice when you receive a new cookie, to disable
          existing cookies, and to omit images (which will disable pixel tags).
          Note that the opt-out will apply only to the browser that you are
          using when you elect to opt out of advertising cookies. Please note,
          without cookies or pixel tags, you may not be able to take full
          advantage of all features of our Services.
        </Typography>
        <Typography variant="body1margin">
          Some web browsers incorporate a “Do Not Track” feature (“DNT”) that
          signals to the websites that you visit that you do not want to have
          your online activity tracked. Many websites and applications,
          including our Services, do not currently respond to web browser DNT
          signals because such signals are not yet uniform. For more information
          about DNT signals, please visit allaboutdnt.com.
        </Typography>
        <Typography variant="body1margin">
          <b>Choices You Have Regarding Your Information </b>
        </Typography>
        <Typography variant="body1margin">
          You may review and request certain changes to the information we have
          collected about you by contacting us (our contact information is at
          the end of this Privacy Policy) or by directly contacting your health
          plan and reviewing its Notice of Privacy Policies.
        </Typography>
        <Typography variant="body1margin">
          <b>Retention of Your Information</b>
        </Typography>
        <Typography variant="body1margin">
          As a general matter, we will keep your personal information for as
          long as necessary to fulfill the purpose for which it was collected.
          If a law requires us to retain your information for a longer period of
          time, we will comply with that law. We will also retain your personal
          information as necessary to protect our legal rights.
        </Typography>
        <Typography variant="body1margin">
          <b>Security of Your Information </b>
        </Typography>
        <Typography variant="body1margin">
          We maintain administrative, technical, and physical safeguards
          designed to protect against unauthorized access, use, modification,
          and disclosure of your information in our custody and control. No
          data, on the Internet or otherwise, can be guaranteed to be 100%
          secure. While we strive to protect your information from unauthorized
          access, use, or disclosure, we cannot and do not ensure or warrant the
          security of your information.
        </Typography>
        <Typography variant="body1margin">
          <b>Children's Privacy</b>
        </Typography>
        <Typography variant="body1margin">
          We do not knowingly collect information, including personal
          information as defined by the Children's Online Privacy Protection
          Act, from children under the age of 13. If you are a parent or
          guardian of a child under the age of 13 and believe he or she has
          disclosed personal information to us, you may contact us to request
          that we delete and stop use of that information (our contact
          information is at the end of this Privacy Policy). If we learn that we
          have received any information directly from a child under age 13
          without first receiving his or her parent's verified consent, we will
          use that information only to respond directly to that child (or his or
          her parent or legal guardian) to inform the child that he or she
          cannot use the Services. We will then subsequently delete that child's
          information.
        </Typography>
        <Typography variant="body1margin">
          <b>Information for Users from Outside of the United States</b>
        </Typography>
        <Typography variant="body1margin">
          The Services are intended for use only in the United States. If you
          use the Services or contact us from outside of the United States,
          please be advised that (1) any information you provide to us or that
          we automatically collect will be transferred to the United States; and
          (2) by using the Services or submitting information, you explicitly
          authorize its transfer to and subsequent processing in the United
          States in accordance with this Privacy Policy.
        </Typography>
        <Typography variant="body1margin">
          <b>Notice to California Residents</b>
        </Typography>
        <Typography variant="body1margin">
          Certain California residents have additional privacy rights under the
          California Consumer Privacy Act (“<b>CCPA</b>”) and the Shine the
          Light law. To exercise your rights, you will need to follow the
          directions that are specific to each law.
        </Typography>
        <Typography variant="body1margin">
          <b>California Consumer Privacy Act</b>
        </Typography>
        <Typography variant="body1margin">
          Note that certain information, such as Protected Health Information
          and other medical information (as defined under the Confidentiality of
          Medical Information Act (“CMIA”)), is exempted from the CCPA. This
          means that we and certain service providers or other recipients may
          not be required to honor the rights described in this section and
          instead we comply with our obligations under other laws, such as HIPAA
          and CMIA.
        </Typography>
        <Typography variant="body1margin">
          The CCPA requires that we make certain disclosures about our general
          collection, use, and disclosure of personal information over the past
          12 months. Accordingly, in the past 12 months:
          <ul>
            <li>
              We have collected the following categories of personal
              information: your direct identifiers, commercial information,
              geolocation data and internet and other electronic network
              activity information. From this information we also may draw
              inferences that reflect your preferences. Examples of types of
              personal information, the sources of this information, and the
              purposes for which we have collected it are detailed in the
              “Information We Collect” and “How We Use and Share Your
              Information” sections above.
            </li>
            <li>
              We disclosed the following categories of personal information for
              a business purpose, but only to service providers that are
              prohibited from using that information for any purpose other than
              providing services to us: your direct identifiers, professional
              and employment-related information, commercial information,
              geolocation data, internet and other electronic network activity
              information and inferences we have made about you.
            </li>
            <li>We have not sold any personal information.</li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          With some exception, you may make a request to exercise the following
          rights with respect to the personal information we maintain about you:{" "}
          <ul>
            <li>
              <b>Right to Know.</b> You have the right to request to know: (1)
              the specific pieces of personal information we have collected
              about you; (2) the categories of personal information we have
              collected about you in the past 12 months; (3) the categories of
              sources from which that personal information was collected; (4)
              the categories of your personal information that we have sold or
              disclosed in the past 12 months; (5) the categories of third
              parties to whom your personal information was sold or disclosed in
              the past 12 months; and (6) the purposes for collecting and
              selling your personal information. You may exercise your right to
              request to know twice a year, free of charge.
            </li>
            <li>
              <b>Right to Delete.</b> You have the right to request that we
              delete the personal information we have collected from you. We may
              deny your request under certain circumstances, such as if we need
              to comply with our legal obligations or complete a transaction for
              which your personal information was collected. If we deny your
              request to delete, we will let you know why.
            </li>
            <li>
              <b>Right to Opt-Out of Sale.</b> You have the right to request
              that we not sell your personal information. [TurningPoint does not
              currently sell any personal information.]
            </li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          If you exercise any of these rights, we will not discriminate against
          you in any way.
        </Typography>
        <Typography variant="body1margin">
          To make a request to exercise your right to know or right to delete,
          contact us (our contact information is at the end of this Privacy
          Policy).
        </Typography>
        <Typography variant="body1margin">
          We will take steps to verify your identity before processing your
          request to know or request to delete. We will not fulfill your request
          unless you have provided sufficient information for us to reasonably
          verify you are the individual about whom we collected personal
          information. If you have an account with us, we will use our existing
          account authentication practices to verify your identity. If you do
          not have an account with us, we may request additional information
          about you so that we can verify your identity.
        </Typography>
        <Typography variant="body1margin">
          You may use an authorized agent to submit a request to know or a
          request to delete. When we verify your agent's request, we may verify
          both your and your agent's identity and request a signed document from
          you that authorizes your agent to make the request on your behalf. To
          protect your personal information, we reserve the right to deny a
          request from an agent that does not submit proof that they have been
          authorized by you to act on your behalf.
        </Typography>
        <Typography variant="body1margin">
          <b>Shine the Light </b>
        </Typography>
        <Typography variant="body1margin">
          California residents may also request information from us once per
          calendar year about any personal information shared with third parties
          for their own direct marketing purposes, including the categories of
          information and the names and addresses of those businesses with which
          we have shared such information. To request this information, please
          contact us (our contact information is at the end of this Privacy
          Policy). Your inquiry must specify “California Privacy Rights Request”
          in the subject line of the email, and you must include your name,
          street address, city, state, and ZIP code.
        </Typography>
        <Typography variant="body1margin">
          TurningPoint does not share personal information for marketing
          purposes.
        </Typography>
        <Typography variant="body1margin">
          <b>Contact Us</b>
        </Typography>
        <Typography variant="body1margin">
          If you have any questions, concerns, complaints or suggestions
          regarding our Privacy Policy or otherwise need to contact us, please
          do so by:
          <ul>
            <li>Emailing us at myhealth@tpshealth.com </li>
          </ul>
        </Typography>
      </Box>
    </>
  );
};

export default PrivacyPolicyPage;
