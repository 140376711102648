import { logAPI } from "./endpoints"

const logEvent = (logType, logEntry) => {
    const url = process.env.REACT_APP_BE_URL + logAPI(logType)

    const body = new Blob([JSON.stringify(logEntry)], { type: "application/json" })
    navigator.sendBeacon(url, body);
}

/**
 * 
 * @param {"trace" | "warning" | "error" | "information"} level 
 * @param {any} logEntry 
 * @returns {{body: {level: "trace" | "warning" | "error" | "information", logEntry: any, sessionToken: string}, session?: {value: string, expiry: number}}}
 */
const generateBody = (level, logEntry) => {
    const session = JSON.parse(localStorage.getItem("token"));
    return {
        body: { level, logEntry, sessionToken: session ? session.value : null },
        session
    }
}

/**
 * Log Page View
 * @param {{message: string, success: boolean, procedure: string, topic: string, url: string}} logEntry 
 * @param {"trace" | "warning" | "error" | "information"} level
 */
function logPageView(level, logEntry) {
    return logEvent("pageview", generateBody(level, logEntry).body);
}

/**
 * Log Auth
 * @param {{message: string, success: boolean, termsAndPrivacyConsent: boolean error: string?}} logEntry 
 * @param {"trace" | "warning" | "error" | "information"} level
 */
function logAuth(level, logEntry) {
    return logEvent("auth", generateBody(level, logEntry).body);
}

/**
 * Log Video
 * @param {string} videoTopicId
 * @param {string} procedure
 * @param {"start" | "end"} type 
 * @param {"trace" | "warning" | "error" | "information"} level
 */
function logVideo(level, procedure, videoTopicId, type) {
    return logEvent("video", generateBody(level, { message: "Video Event", procedure, type, videoTopicId }).body);
}


/**
 * Log Feedback
 * @param {{message: string, referToSomeone: boolean, score: int}} logEntry 
 * @param {"trace" | "warning" | "error" | "information"} level
 */
function logFeedback(level, logEntry) {
    return logEvent("feedback", generateBody(level, logEntry).body);
}

/**
 * Log Error
 * @param {string} message 
 * @param {"Auth" | "Feedback" | "PageView" | "Session" | "Frontend"} errorType 
 * @param {Error} error 
 * @returns 
 */
function logError(message, errorType, error) {
    return logEvent("error", generateBody("error", { message, description: error.message, stackTrace: error.stack, errorType }).body);
}

/**
 * Log Session
 * @param {{message: string, success: boolean}} logEntry 
 * @param {"trace" | "warning" | "error" | "information"} level
 */
function logSession(level, logEntry) {
    const req = generateBody(level, logEntry);
    return logEvent("session", {...req.body, logEntry: {...req.body.logEntry, expiresAt: req.session ? req.session.expiry : 0}});
}

const log = {
    pageView: logPageView,
    auth: logAuth,
    feedback: logFeedback,
    error: logError,
    session: logSession,
    video: logVideo
}

export default log;