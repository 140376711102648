import { Button, Link, Tooltip } from "@mui/material";

const IconButtonTooltip = ({ sx, Icon, href, tooltipTitle, ...props }) => {
  return (
    <Tooltip title={tooltipTitle} arrow enterTouchDelay={0} leaveTouchDelay={0}>
      <Button LinkComponent={Link} href={href}
        sx={{
          borderRadius: 8,
          borderWidth: 3,
          borderColor: (t) => t.palette.primary.light,
          ":hover": {
            borderColor: (t) => t.palette.primary.light,
            backgroundColor: (t) => t.palette.primary.light,
            borderRadius: 8,
            borderWidth: 3,
            color: "white",
          },
          ":focus": {
            borderColor: (t) => t.palette.primary.light,
            backgroundColor: (t) => t.palette.primary.light,
            borderRadius: 8,
            borderWidth: 3,
            color: "white",
          },
          ":hover .icon-button-end-icon": {
            transition: (t) => t.transitions.duration,
            backgroundColor: "transparent",
            borderColor: "white",
            borderWidth: 2,
            borderStyle: "solid",
          },
          ":focus .icon-button-end-icon": {
            transition: (t) => t.transitions.duration,
            backgroundColor: "transparent",
            borderColor: "white",
            borderWidth: 2,
            borderStyle: "solid",
          },
          ...sx,
        }}
        {...props}
        variant="outlined"
      >
        {props.children}
        <Icon
          sx={{
            backgroundColor: (t) => t.palette.primary.light,
            padding: 0.3,
            marginLeft: 1,
            borderRadius: 8,
            color: "white",
            ":hover": {
              backgroundColor: "white",
            },
          }}
          {...props}
          className="icon-button-end-icon"
        />
      </Button>
    </Tooltip>
  );
};

export default IconButtonTooltip;
