import { Grid, Typography } from "@mui/material";
import { StyledButton } from "./ProcedureBox.styles";
import { titleCase } from "../../utils/string";

const ProcedureBox = ({ isSelected, onSelect, item, size }) => {
  const { Icon, label } = item;

  return (
    <Grid item xs={12} sm={6} md={3} lg={3} onClick={onSelect}>
      <StyledButton
        fullWidth
        startIcon={
          <Icon
            color={isSelected ? "primary" : "inherit"}
            style={{ marginRight: -4, marginLeft: 8 }}
          />
        }
      >
        <Typography
          p={0}
          color={isSelected ? "primary" : "inherit"}
          variant="body2"
          fontSize={[14, 16]}
          fontWeight={600}
        >
          {titleCase(label)}
        </Typography>
      </StyledButton>
    </Grid>
  );
};

export default ProcedureBox;
