import { Typography, styled } from "@mui/material";

// Alternative Typography component that matches the h4
// heading styling without using actual h4 components
const TypographyT4 = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.text.secondary,
  lineHeight: "normal",
  paddingBottom: 11,
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
}));

export default TypographyT4;
