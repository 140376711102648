import { Grid } from "@mui/material";
import { StyledButton } from "./AnchorBox.styles";
import { titleCase } from "../../utils/string";
import TypographyT4 from "../Basic/Typography/TypographyT4";

const AnchorBox = ({ item, size }) => {
  const anchor = item.replaceAll(" ", "_");

  return (
    <Grid item xs={6} sm={6} md={4} lg={3}>
      <StyledButton href={`#${anchor}`} fullWidth>
        <TypographyT4 fontSize={[14, 16]} fontWeight={600} p={0}>
          {titleCase(item)}
        </TypographyT4>
      </StyledButton>
    </Grid>
  );
};

export default AnchorBox;
