import { Box, Dialog, Typography } from "@mui/material";
import useToken from "../../hooks/useToken";
import MemberModal from "../MemberModal/MemberModal";
import Line from "../Line/Line";
import { Link } from "react-router-dom";
import Image from "../../components/Image/Image";

const MemberPopUp = () => {
  const { token, setToken } = useToken();
  return (
    <Dialog fullWidth maxWidth="md" open={!!!token}>
      <Box
        px={2}
        display="flex"
        alignItems={["flex-start", "center"]}
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        marginBottom={1}
        marginTop={1}
      >
        <Link reloadDocument to={"/"}>
          <Image
            logo
            width="auto"
            height={50}
            src="/static/img/logo.svg"
            alt="TurningPoint logo"
          />
        </Link>
        <Typography fontSize={24} fontWeight={400} color="text.dark">
          Education Library
        </Typography>
      </Box>
      <Line />
      <MemberModal
        setToken={setToken}
        isPopup
      />
    </Dialog>
  );
};

export default MemberPopUp;
