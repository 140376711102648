import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.REACT_APP_AZURE_INSIGHTS_CONNECTION,
		extensions: [reactPlugin],
		enableAutoRouteTracking: false,
		autoTrackPageVisitTime: false,
	},
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
