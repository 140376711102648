export const articleSummary = {
	knee: [
		{
			id: "abn1940",
			title: "Knee Replacement Surgery",
			summary:
				"Learn what to expect during knee replacement surgery and how it can ease the pain of arthritis.",
			isVideo: true,
		},
		{
			id: "abu5535",
			title: "How do you prepare for total knee replacement?",
			summary:
				"Surgery can be stressful. This information will help you understand what you can expect. And it will help you safely prepare for surgery.",
		},
		{
			id: "acd9477",
			title: "Knee Replacement: Learning About Rehab",
			summary:
				"Learn more about doing rehab right after knee replacement surgery, so you can find that sweet spot between resting and getting stronger.",
			isVideo: true,
		},
		{
			id: "abq5098",
			title: "Knee Replacement: Recovery",
			summary:
				"Learn about recovering from knee replacement surgery, and listen to stories about different recovery experiences.",
			isVideo: true,
		},
	],

	hip: [
		{
			id: "ace8867",
			title: "Total Hip Replacement Surgery: Overview",
			summary:
				"Total hip replacement is a step-by-step surgery to replace the hip socket and the ball at the top of the thighbone (femur).",
		},
		{
			id: "abp6771",
			title: "Hip Replacement Surgery: Returning Home",
			summary:
				"Learn how to take care of yourself at home after hip replacement surgery.",
			isVideo: true,
		},
		{
			id: "abp8378",
			title: "When Can You Be Active Again?",
			summary:
				"Learn how long it may take to start doing your daily activities again after a hip replacement.",
			isVideo: true,
		},
	],

	spine: [
		{
			id: "abu5416",
			title: "How Do You Prepare For Cervical Spinal Fusion?",
			summary:
				"Surgery can be stressful. This information will help you understand what you can expect. And it will help you safely prepare for surgery.",
		},
		{
			id: "acf1892",
			title: "What Can You Expect As You Recover From Cervical Spinal Fusion?",
			summary:
				"This surgery usually requires a short stay in the hospital. You may need to wear a brace on your neck (cervical collar) while you recover.",
		},
		{
			id: "abu5419",
			title: "After Cervical Spinal Fusion: Overview",
			summary:
				"Cervical spinal fusion is surgery that joins two or more of the vertebrae in your neck. It made your neck more stable.",
		},
	],

	shoulder: [
		{
			id: "acf1373",
			title:
				"Subacromial Smoothing And Acromioplasty For Rotator Cuff Disuorders: Overview",
			summary:
				"Surgery may be used to treat a rotator cuff disorder if the injury is very bad or if nonsurgical treatment has not improved shoulder strength and movement enough.",
		},
		{
			id: "abu1287",
			title: "How Do You Prepare For Shoulder Arthroscopic Surgery?",
			summary:
				"Surgery can be stressful. This information will help you understand what you can expect. And it will help you safely prepare for surgery.",
		},
		{
			id: "acf1374",
			title:
				"What Can You Expect As You Recovery From Subacromial Smoothing And Acromioplasty For Rotator Cuff Disorders?",
			summary:
				"You will likely go home a few hours after you wake up from anesthesia. A family member or friend should drive you home.",
		},
	],
};
