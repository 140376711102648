import { Button, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { titleCase } from "../../utils/string";

const Breadcrumbs = ({
  isBack,
  backTitle = "Back",
  breadcrumbs = [],
  onClickBreadcrumb = () => {},
  ...props
}) => {
  if (isBack) breadcrumbs.push(`< ${backTitle}`);

  return (
    <MuiBreadcrumbs
      py={1}
      px={[3, 16]}
      separator={<KeyboardArrowRight />} //›
      aria-label="breadcrumbs"
      {...props}
    >
      {breadcrumbs.map((title, index) => (
        <Button
          key={title}
          color="primary"
          sx={{
            ":hover": { color: "text.dark", fontWeight: "600" },
            ":focus": { color: "text.dark", fontWeight: "600" },
          }}
          onClick={() => onClickBreadcrumb(index)}
        >
          {titleCase(title)}
        </Button>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
