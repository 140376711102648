import styled from "@emotion/styled";
import { Box } from "@mui/material";
import TpsBanner from "../../../assets/img/tps-banner.jpeg";

export const StyledArticleBanner = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "12.5rem",
  background: `url(${TpsBanner})`,
  backgroundColor: "#012255",
  backgroundSize: "110% 850%",
  backgroundPosition: "260% 65%", //"-490px -620px",
  backgroundRepeat: "no-repeat",

  [theme.breakpoints.down("lg")]: {
    backgroundSize: "500%",
    backgroundPosition: "33% 60%",
  },
}));
