import { Typography, styled } from "@mui/material";

// Alternative Typography component that matches the h5
// heading styling without using actual h5 components
const TypographyT5 = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

export default TypographyT5;
