import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ProcedureBox from "../../components/ProcedureBox/ProcedureBox";
import Image from "../../components/Image/Image";
import InfoIcon from "@mui/icons-material/Info";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { procedureItems, procedurePages } from "../../data/procedures";
import TPSFlyerImg from "../../assets/img/tps-flyer.png";
import { getProcedureIndexFromURL } from "../../utils/getProcedureIndexFromURL";
import BlueButton from "../Basic/BlueButton/BlueButton";
import api from "../../api";
import { authAPI } from "../../api/endpoints";
import axios from "axios";
import log from "../../api/log";

const InfoAdornment = ({ tooltipText, sx }) => (
  <InputAdornment position="start">
    <Tooltip title={tooltipText} arrow enterTouchDelay={0} leaveTouchDelay={0}>
      <InfoIcon
        sx={{
          color: (t) => t.palette.primary.main,
          cursor: "pointer",
          ...sx,
        }}
      />
    </Tooltip>
  </InputAdornment>
);

const MemberModal = ({ setToken, isPopup }) => {
  const navigate = useNavigate();
  const [codeError, setCodeError] = useState("");
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [accessCode, setAccessCode] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const [findAccessCodeModal, setFindAccessCodeModal] = useState(false);

  const isSubmitValid =
    (!isPopup ? selectedProcedure !== null : true) &&
    acceptTerms &&
    accessCode !== "";

  const handleMemberSubmit = async (event) => {
    event.preventDefault();

    setCodeError("");

    if (isSubmitValid) {

      setLoading(true);

      try {
        const authResult = await api.post(authAPI, { accessCode });

        if (authResult.status >= 200 && authResult.status <= 299) {
          setToken(authResult.data.token);
          log.auth("information", { message: "Auth success", success: true, termsAndPrivacyConsent: acceptTerms });
          const procedureItem = procedureItems[selectedProcedure];
          if (procedurePages?.[procedureItem?.slug]) {
            if (!isPopup) {
              navigate(`/procedures/${procedureItem.slug}`);
            }
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response.status === 404) {
            setCodeError(
              "Access Code is not found. Please check your access code and try again."
            );
            log.auth("error", {
              message: "Auth failed due to case doesn't exist in CRM",
              success: false,
              termsAndPrivacyConsent: acceptTerms,
              error: error.message,
            });
          } else {
            setCodeError("Access Code could not be validated");
            log.error("Auth failed on backend", "Auth", error);
          }
        } else {
          setCodeError("Something went wrong");
          log.error("Auth failed on frontend", "Auth", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleFindAccessCodeModal = () => {
    setFindAccessCodeModal(!findAccessCodeModal);
  };

  useEffect(() => {
    if (isPopup) {
      const selectedProcedureIndex = getProcedureIndexFromURL(
        window.location.pathname,
        "procedures"
      );

      if (selectedProcedureIndex !== -1) {
        setSelectedProcedure(selectedProcedureIndex);
      }
    }
  }, [isPopup]);

  return (
    <Container maxWidth="md">
      <Box textAlign="center">
        <Typography my={2} display={"inline-block"} textAlign={"justify"}>
          <span style={{ fontWeight: "bold" }}>
            Welcome to TurningPoint's Health Education program!
          </span>{" "}
          We are thrilled you have chosen to have the best outcomes possible by
          learning more about our program. You will find this content is quick
          and easy to learn. We are here to help your health journey be a
          success! <span style={{ fontWeight: "bold" }}>Best of all,</span> it's
          free thanks to TurningPoint and your health plan.
        </Typography>
        <Typography
          marginBottom={2}
          display={"inline-block"}
          textAlign={"justify"}
        >
          Do you feel nervous about your procedure? Don't worry, you are not
          alone. 2 out of 3 people feel nervous before a procedure. This program
          is meant to put your worries at ease and prepare you for your
          procedure. This website is full of content about your specific surgery
          or procedure. Each page is hand-crafted by our experts to help answer
          your health questions and set you at ease.
        </Typography>
        <Typography
          marginBottom={2}
          display={"inline-block"}
          textAlign={"justify"}
        >
          Be sure to check out our{" "}
          <span style={{ fontWeight: "bold" }}>General Health Information</span>{" "}
          topic. This section is where you will find other helpful info about
          healthcare and how to improve your health.
        </Typography>
        <Typography
          marginBottom={2}
          display={"inline-block"}
          textAlign={"justify"}
        >
          <span style={{ fontWeight: "bold" }}>Please note:</span> This website
          is only for people who received a Health Education flyer from
          TurningPoint in the mail. The site cannot give healthcare advice, so
          always talk to your doctor before changing your care plan.
        </Typography>
        <Typography my={3} color="text.primary">
          Please enter your Access Code to view your education library
        </Typography>
        <Box
          my={2}
          mx={[2, 6, 32]}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <TextField
            fullWidth
            type="text"
            id="accessCode"
            name="accessCode"
            placeholder="Enter your Access Code"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            error={codeError.length > 0}
            helperText={codeError}
            FormHelperTextProps={{ sx: { textAlign: "center" } }}
            InputProps={{
              sx: { pl: 1 },
              startAdornment: (
                <InfoAdornment
                  tooltipText={
                    <span>
                      Please enter the <b>Access Code</b> located under the QR code in
                      the middle left-hand side of your TurningPoint flyer.
                      Please enter the code as it appears on the flyer without
                      any spaces. The textbox is case sensitive.
                    </span>
                  }
                  sx={{ pr: 0.5 }}
                />
              ),
            }}
          />
          <Box>
            <Button
              mt={2}
              sx={{
                fontSize: 12,
                alignSelf: "flex-end",
                "&:focus": { color: "text.primary" },
                "&:hover": { color: "text.primary" },
              }}
              onClick={toggleFindAccessCodeModal}
            >
              {findAccessCodeModal
                ? "Close Sample TurningPoint flyer"
                : "Can’t find your Access Code?"}
            </Button>
          </Box>

          <Collapse in={findAccessCodeModal}>
            <Image
              src={TPSFlyerImg}
              alt="Flyer showing zoomed-in Access Code needed to access Health Education site in top right corner of page."
              padding={20}
            />
          </Collapse>
        </Box>

        {!isPopup && (
          <>
            <Typography color="text.primary">
              What topic do you want to learn about?
            </Typography>

            <Box sx={{ p: "10px", my: 2 }}>
              <Grid
                container
                spacing={2}
                justifyContent={["flex-start", "space-evenly"]}
              >
                {procedureItems.map((item, idx) => (
                  <ProcedureBox
                    item={item}
                    key={item.label}
                    size={procedureItems.length}
                    isSelected={selectedProcedure === idx}
                    onSelect={() => setSelectedProcedure(idx)}
                  />
                ))}
              </Grid>
            </Box>
          </>
        )}

        <Box
          my={2}
          mx={[2, 6, 28]}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTerms}
                onChange={(e) => {
                  setAcceptTerms(e.target.checked);
                }}
                sx={{
                  color: "#CCC",
                  "&:focus-within": {
                    background: "rgba(32, 140, 255, 0.04)",
                  },
                }}
              />
            }
            label={
              <div>
                <span>I accept the </span>
                <Link
                  to={"/termsOfService"}
                  target="_blank"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                >
                  Terms of Service
                </Link>
                <span> and </span>
                <Link
                  to={"/privacyPolicy"}
                  target="_blank"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
              </div>
            }
          />
        </Box>

        <BlueButton
          type="submit"
          disabled={!isSubmitValid}
          style={{ margin: "0 30px 23px", width: 180, padding: "8px 50px" }}
          onClick={handleMemberSubmit}
        >
          {loading ? <CircularProgress size={20} /> : "Get Started"}
        </BlueButton>
        <Box>
          <Typography fontSize="0.7em" marginBottom={2}>
            TurningPoint does not provide medical care, or any other services
            related to the medical diagnosis, advice, or treatment of patients.
            TurningPoint’s care team and the information made available through
            this service is not intended to be a substitute for medical care
            provided by your physician or other health care provider but is
            intended to be used for informational purposes only.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default MemberModal;
