const getInjectedVideoHtml = (topic, procedure, slug) => {
  const videoWrapper = document.createElement("div");
  videoWrapper.innerHTML = topic.html;

  const video = videoWrapper.getElementsByTagName("video")?.[0];
  video.setAttribute("topicId", slug)
  video.setAttribute("procedure", procedure)

  const higherQualities = ["1080p", "720p"];
  const highestQualitySrc = higherQualities.map(
    (quality) =>
      Object.entries(topic.sources).find(([label, url]) =>
        label.includes(quality)
      )[1]
  );

  if (highestQualitySrc) {
    video.src = highestQualitySrc;
  }

  Object.entries(topic.sources).forEach(([label, url]) => {
    const isMp4 = label.includes("mp4");
    const isHls = label.includes("hls");
    const source = document.createElement("source");
    source.src = url;
    source.ariaLabel = label;
    source.type = isMp4 ? "video/mp4" : isHls ? "application/x-mpegURL" : "";

    video.append(source);
    video.controlsList = "nodownload";
  });

  return videoWrapper.outerHTML;
};

export default getInjectedVideoHtml;
