import { Box, Typography } from "@mui/material";
import TypographyT4 from "../components/Basic/Typography/TypographyT4";
import { useEffect } from "react";
import log from "../api/log";

const NotFound = () => {
  useEffect(() => {
    log.pageView("information", {message: "Page Not Found", success: false, url: window.location.href})
  }, [])
  return (
    <Box
      height={"80vh"}
      display={"flex"}
      flexDirection={"column"}
      flexWrap={"wrap"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Typography variant="h1" alignSelf={"center"}>
        404
      </Typography>
      <TypographyT4>
        Uh oh! Sorry, the page you're looking for does not exist.
      </TypographyT4>
    </Box>
  );
};

export default NotFound;
