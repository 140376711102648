import { Box, Typography } from "@mui/material";
import { StyledBanner } from "./Banner.styles";
import TypographyT5 from "../../../components/Basic/Typography/TypographyT5";

const Banner = ({
  title = "Your Health Journey",
  subtitle = "Health Information at Your Fingertips",
  reverseTitle = false,
  imageSrc,
  height = 440,
}) => {
  return (
    <StyledBanner
      imageSrc={imageSrc}
      reverseTitle={reverseTitle}
      justifyContent={reverseTitle ? "flex-end" : "initial"}
      height={height}
    >
      <Box py={[6, "auto"]} px={[6, "7em"]}>
        <Typography variant="h1"> {title} </Typography>
        <TypographyT5 color="text.dark" width={["70%", "auto"]}>
          {subtitle}
        </TypographyT5>
      </Box>
    </StyledBanner>
  );
};

export default Banner;
