import { createTheme } from "@mui/material";
import { defaultPalette } from "./default";

export const theme = createTheme({
  palette: defaultPalette,
  components: {
    MuiButtonBase: {
      defaultProps: { disableRipple: true },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: ["Open Sans", "sans-serif"].join(","),
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          stroke: "currentColor",
          fill: "currentColor",
        },
      },
    },
  },
});

theme.typography = {
  ...theme.typography,
  h1: {
    fontSize: 56,
    fontWeight: 400,
    lineHeight: "normal",
    color: defaultPalette.text.dark,
    paddingBottom: 11,
    [theme.breakpoints.down("lg")]: {
      fontSize: 40,
      paddingBottom: 14,
    },
  },
  h2: {
    fontSize: 36,
    fontWeight: 700,
    color: defaultPalette.text.dark,
    paddingBottom: 21,
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
    },
  },
  h3: {
    fontSize: 32,
    fontWeight: 400,
    color: defaultPalette.text.dark,
    [theme.breakpoints.down("lg")]: {
      fontSize: 22,
    },
  },
  h4: {
    fontSize: 20,
    fontWeight: 700,
    color: defaultPalette.text.secondary,
    lineHeight: "normal",
    paddingBottom: 11,
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
  },
  h5: {
    fontSize: 20,
    fontWeight: 400,
    color: defaultPalette.text.dark,
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
  },
  h6: {
    fontSize: 16,
    fontWeight: 400,
  },
  body1: {
    color: defaultPalette.text.neutral,
    lineHeight: 1.56,
  },
  body1margin: {
    color: defaultPalette.text.neutral,
    lineHeight: 1.56,
    marginBottom: "1em",
  },
  body2: { fontSize: 12, color: defaultPalette.text.neutral, lineHeight: 1.56 },
  fontFamily: ["Open Sans", "sans-serif"].join(","),
};
