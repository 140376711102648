import { useNavbar } from "../contexts/NavbarProvider";
import React, { useEffect, useState } from "react";

const useNavbarProcedureModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setProcedurePage, setOnProcedureModal } = useNavbar();
  useEffect(() => {
    setOnProcedureModal(() => () => {
      setModalOpen(true);
    });

    return () => {
      setOnProcedureModal(null);
    };
  }, [setProcedurePage, setOnProcedureModal]);

  return {
    modalOpen,
    setModalOpen,
  };
};

export default useNavbarProcedureModal;
