import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Image from "../Image/Image";

import { FeedbackRate, SelectButton } from "./FeedbackModal.styles";
import useFeedbackSession from "../../hooks/useFeedbackSession";
import BlueButton from "../Basic/BlueButton/BlueButton";
import log from "../../api/log";

const FeedbackModal = ({ procedure, topic }) => {
  const [feedback, setFeedback] = useState(null);
  const [feedbackRate, setFeedbackRate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const { feedbackSession, setFeedbackSession } = useFeedbackSession();

  const isValid = feedback !== null && feedbackRate !== null;

  useEffect(() => {
    if (!!!feedbackSession) {
      const timer = setTimeout(() => {
        setModalOpen(true);
      }, 60000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [feedbackSession]);

  const handleMemberSubmit = (event) => {
    event.preventDefault();
    setModalOpen(false);
    setFeedbackSession();
    log.feedback("information", {message: "Feedback event", referToSomeone: feedback, score: feedbackRate, procedure, topic})
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={modalOpen}
      PaperProps={{ component: "form", onSubmit: handleMemberSubmit }}
    >
      <DialogTitle>
        <Box
          px={2}
          display="flex"
          alignItems={["flex-start", "center"]}
          flexDirection={["column", "row"]}
          justifyContent="space-between"
        >
          <Image
            logo
            width="auto"
            height={50}
            src="/static/img/logo.svg"
            alt="TurningPoint logo"
          />

          <IconButton
            sx={{
              ":hover": {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
              },
              ":focus": {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
              },
            }}
            onClick={() => {
              setFeedbackSession();
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box textAlign="center">
          <DialogContent sx={{ p: 0 }}>
            <DialogContentText my={2} color="text.primary">
              Did you find this content useful?
            </DialogContentText>

            <SelectButton
              color="primary"
              onClick={() => setFeedback(false)}
              variant={feedback === false ? "contained" : "outlined"}
            >
              No
            </SelectButton>
            <SelectButton
              color="primary"
              onClick={() => setFeedback(true)}
              variant={!!feedback ? "contained" : "outlined"}
            >
              Yes
            </SelectButton>
          </DialogContent>

          <DialogContent sx={{ p: 0 }}>
            <DialogContentText my={1} color="text.primary">
              How likely are you to refer this to someone else in need?
            </DialogContentText>

            <Grid container justifyContent="center" my={2}>
              {[...Array(10).keys()].map((n) => (
                <FeedbackRate
                  isSelected={n + 1 === feedbackRate}
                  onClick={() => setFeedbackRate(n + 1)}
                >
                  {n + 1}
                </FeedbackRate>
              ))}
            </Grid>

            <Typography fontSize={12}>
              Your satisfaction rating: 1 - Unsatisfied to 10 - Very Satisfied
            </Typography>
          </DialogContent>

          <DialogContent sx={{ pb: 0, mt: 1 }}>
            <BlueButton
              type="submit"
              disabled={!isValid}
              style={{
                margin: "0 30px 23px",
                padding: "8px 50px",
              }}
            >
              Submit Feedback
            </BlueButton>
          </DialogContent>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackModal;
