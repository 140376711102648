import { Typography } from "@mui/material";
import { titleCase } from "../../../utils/string";
import { StyledArticleBanner } from "./ArticleBanner.styles";

const ArticleBanner = ({ title }) => {
  return (
    <StyledArticleBanner px={[3, 16]}>
      <Typography
        variant="h1"
        fontSize="36px"
        fontWeigh={700}
        color="white"
        p={0}
      >
        {titleCase(title)}
      </Typography>
    </StyledArticleBanner>
  );
};

export default ArticleBanner;
