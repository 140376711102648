import { useEffect, useState } from "react";
import { getWithExpiry, setWithExpiry } from "../utils/storage";
import { sessionLength } from "../data/constants";

const useFeedbackSession = () => {
  const [feedbackSession, setFeedbackSession] = useState(
    getWithExpiry("feedbackSession")
  );

  useEffect(() => {
    const feedbackValid = getWithExpiry("feedbackSession");
    setFeedbackSession(feedbackValid);
  }, []);

  return {
    feedbackSession,
    setFeedbackSession: () => {
      setWithExpiry("feedbackSession", "valid", sessionLength);
      setFeedbackSession("valid");
    },
  };
};

export default useFeedbackSession;
