const articleAPI = (id) => `/v1/content/articles/${id}`;
const topicsAPI = (id) => `/v1/content/topics/${id}`;
const contentAPI = (phase, subphase) =>
  `/v1/content/topics/${phase}/${subphase}`;

const logAPI = (logType) => `/v1/log/${logType}`;

export const authAPI = `/v1/auth`;

export { articleAPI, topicsAPI, contentAPI, logAPI };
