import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useTrackPageView = () => {
	const location = useLocation();
	const appInsightCtx = useAppInsightsContext();

	const [name, setName] = useState("");
	const [properties, setProperties] = useState();

	useEffect(() => {
		const appInsights = appInsightCtx.getAppInsights();

		if (name) {
			appInsights.trackPageView(
				{ name, uri: location.pathname },
				{ ...properties }
			);
		}
	}, [appInsightCtx, location.pathname, name, properties]);

	return useCallback((name, customProperties) => {
		setName(name);
		setProperties(customProperties);
	}, []);
};

export default useTrackPageView;
