import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: "6px 12px",
  width: 180,
  height: 60,
  borderRadius: 6,
  background: "#FFF",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
  alignContent: "center",
  color: theme.palette.text.disabled,
  ":focus": {
    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.5)",
    color: theme.palette.text.primary,
  },

  [theme.breakpoints.down("lg")]: {
    padding: 0,
    height: 50,
    width: 160,
  },
}));
