import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Image from "../../../components/Image/Image";
import ReadArticle from "../../../components/ReadArticle/ReadArticle";
import ArticleOverview from "../ArticleOverview/ArticleOverview";
import { CategorySection } from "./EducationCategory.styles";
import TypographyT4 from "../../../components/Basic/Typography/TypographyT4";

const EducationCategory = ({
  title = "",
  procedureSlug = "",
  subphases = [],
  imageSrc = "",
  imageAlt = "",
  isImageFirst = false,
  darkBg = false,
  isGeneral = false,
  paragraphs = [],
}) => {
  const navigate = useNavigate();

  const anchorId = title?.replaceAll(" ", "_");

  const firstProcedureTitle = isGeneral ? "" : subphases[0].title;

  const firstProcedureParagraphs = isGeneral
    ? paragraphs
    : subphases[0].paragraphs;

  const overviewTitle = "Procedure Steps";

  const handleReadArticle = (evt) => {
    if (isGeneral) {
      navigate(`/topics/general/${procedureSlug}`);
    } else {
      const slug = subphases[0].slug;
      slug && navigate(`/topics/${procedureSlug}/${slug}`);
    }
  };
  var bottomPadding = isGeneral ? 6 : 4;

  return (
    <CategorySection
      id={anchorId}
      container
      direction="column"
      darkBg={darkBg}
      margin={0}
      px={[3, 16]}
      py={[2, bottomPadding]}
    >
      <Grid item>
        <Typography variant="h2">{title}</Typography>
      </Grid>

      <Grid
        item
        gap={[2, 4, 10]}
        display="flex"
        flexDirection={
          isImageFirst ? ["column", "row"] : ["column", "row-reverse"]
        }
      >
        <Grid
          item
          container
          sm={12}
          md={6}
          justifyContent={isImageFirst ? "normal" : "end"}
        >
          <Image
            src={imageSrc}
            width={510}
            height={263}
            maxHeight={500}
            objectFit="cover"
            alt={imageAlt || title}
          />
        </Grid>

        <Grid item sm={12} md={6}>
          {!isGeneral && <TypographyT4>{firstProcedureTitle}</TypographyT4>}
          {firstProcedureParagraphs.map((text) => (
            <Typography key={text} variant="body1" paragraph>
              {text}
            </Typography>
          ))}
          <ReadArticle onClick={handleReadArticle} />
        </Grid>
      </Grid>
      {!isGeneral && (
        <ArticleOverview
          title={overviewTitle}
          subphases={subphases}
          procedureSlug={procedureSlug}
        />
      )}
    </CategorySection>
  );
};

export default EducationCategory;
