export const defaultPalette = {
  text: {
    primary: "#232A3F",
    secondary: "#0052B1",
    neutral: "#232A3F",
    dark: "#002254",
    disabled: "#595959",
  },
  primary: {
    main: "#0052B1",
    light: "#0052B1",
    dark: "#002254",
  },
  background: {
    primary: "#FFF",
    secondary: "#F7F7F7",
    dark: "#D9D9D9",
  },
};
