import { Grid, Typography } from "@mui/material";
import Image from "../../../components/Image/Image";
import Healthwise from "../../../assets/img/healthwise.png";

const Disclaimer = () => {
  return (
    <Grid rowGap={1} px={[3, 16]} mt={1}>
      <Typography variant="body2">
        TurningPoint does not provide medical care, or any other services
        related to the medical diagnosis, advice, or treatment of patients.
      </Typography>

      <Typography variant="body2">
        TurningPoint’s care team and the information made available through this
        service is not intended to be a substitute for medical care provided by
        your physician or other health care provider but is intended to be used
        for informational purposes only.
      </Typography>

      <Image logo height={50} width={107} src={Healthwise} />

      <Typography variant="body2">
        This information does not replace the advice of a doctor. Healthwise,
        Incorporated, disclaims any warranty or liability for your use of this
        information. Your use of this information means that you agree to the
        Terms of Use and Privacy Policy. To learn more about Healthwise, visit
        Healthwise.org
      </Typography>

      <Typography variant="body2" paragraph>
        ©1995-{new Date().getFullYear()} Healthwise, Incorporated. Healthwise,
        Healthwise for every health decision, and the Healthwise logo are
        trademarks of Healthwise, Incorporated
      </Typography>
    </Grid>
  );
};

export default Disclaimer;
