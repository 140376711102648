import React from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./themes";
import { router } from "./routes";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/insights";
import reportWebVitals from "./reportWebVitals";
import log from "./api/log"

import "./scss/main.scss";

window.addEventListener("error", function (event, source, lineno, colno, error) {
  log.error("Frontend error", "Frontend", error)
})

window.addEventListener("unhandledrejection", function (ev) {
  log.error("Promise error on frontend", "Frontend", ev.reason)
})

const rootEl = document.getElementById("root");

const root = ReactDOM.createRoot(rootEl);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <RouterProvider router={router} />
      </AppInsightsContext.Provider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
