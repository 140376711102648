import { useParams } from "react-router-dom";
import Article from "./components/ArticlePages/Article";
import GeneralHealthArticle from "./components/ArticlePages/GeneralHealthArticle";
import { useEffect } from "react";
import log from "../api/log";
import { getWithExpiry } from "../utils/storage";

const ArticlePage = () => {
  const { procedureSlug, slug } = useParams();

  useEffect(() => {
    if (getWithExpiry("token")) {
      log.pageView("information", {
        message: "Article Page View",
        procedure: procedureSlug,
        topic: slug,
        success: true,
        url: window.location.href,
      });
    } else {
      log.pageView("warning", {
        message: "Article page view (session expired, modal appeared)",
        procedure: procedureSlug,
        topic: slug,
        success: false,
        url: window.location.href,
      });
    }
  }, [procedureSlug, slug]);

  return (
    <>
      {procedureSlug === "general" ? (
        <GeneralHealthArticle
          procedureSlug={procedureSlug}
          categorySlug={slug}
        />
      ) : (
        <Article procedureSlug={procedureSlug} slug={slug} />
      )}
    </>
  );
};

export default ArticlePage;
