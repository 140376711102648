import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import api from "../../../api";
import { contentAPI } from "../../../api/endpoints";
import getInjectedVideoHtml from "../../../utils/video";
import log from "../../../api/log";
import useLogVideo from "../../../hooks/useLogVideo";

const ArticleAccordion = ({ subphase, procedureSlug }) => {
  const title = subphase.title;
  const slug = subphase.slug;
  const [content, setContent] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const contentRef = useRef(null);

  const fetchTopics = async () => {
    try {
      const response = await Promise.resolve(api(contentAPI(slug, "article")));
      if (response.status === 200) {
        const topicData = response.data.hwContent
          .map((resp) => {
            const topic = resp.data?.topics?.[0];
            if (topic.type === "hwVideo") return getInjectedVideoHtml(topic, procedureSlug, slug); 
            return topic.html;
          })
          .join("\n");

        let html = topicData.replaceAll("h3", "h5");
        setContent(html);
        !topicData && setNotFound(true);
      }
    } catch (error) {
      setNotFound(true);
      log.error("Topics couldn't be fetched", "Frontend", error)
    }
  };

  //  TODO: Determine if we need to Submit Tracking for video events
  useLogVideo(contentRef);

  return (
    <Accordion
      onChange={(e, expanded) => {
        e.preventDefault();
        expanded && fetchTopics();
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${title}-panel-header`}
        aria-controls={`${title}-panel-content`}
      >
        <Typography
          variant="h2"
          fontSize="32px"
          fontWeight="550"
          paddingBottom="0px"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {notFound ? (
          <Typography>Topic not found</Typography>
        ) : content ? (
          <Container disableGutters maxWidth="auto">
            <div
              ref={contentRef}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Container>
        ) : (
          <Skeleton />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ArticleAccordion;
