import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import useTrackPageView from "../hooks/useTrackPageView";
import Banner from "./components/Banner/Banner";
import Procedures from "./components/Procedures/Procedures";
import EducationCategory from "./components/EducationCategory/EducationCategory";

import { procedurePages } from "../data/procedures.js";
import { titleCase } from "../utils/string";
import ProcedureModal from "../components/ProcedureModal/ProcedureModal.jsx";
import MemberPopUp from "../components/MemberPopUp/MemberPopUp.jsx";
import NotFound from "./NotFound.jsx";
import { extendExpiry, getWithExpiry } from "../utils/storage.js";
import { sessionLength } from "../data/constants.js";
import useNavbarProcedureModal from "../hooks/useNavbarProcedureModal.js";
import log from "../api/log.js";

const ProcedurePage = () => {
  const { modalOpen, setModalOpen } = useNavbarProcedureModal();

  const trackPageView = useTrackPageView();

  const { procedure } = useParams();
  let procedureTitle;

  useEffect(() => {
    extendExpiry("token", sessionLength);

    if (getWithExpiry("token")) {
      log.pageView("information", {
        message: "Procedure page view",
        success: true,
        procedure,
        url: window.location.href,
      });
    } else {
      log.pageView("warning", {
        message: "Procedure page view (session expired, modal appeared)",
        success: false,
        procedure,
        url: window.location.href,
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [procedure]);

  useEffect(() => {
    if (procedureTitle) {
      trackPageView("Procedure Page Visit", {
        procedure,
        title: procedureTitle,
      });
    }
  }, [trackPageView]);

  const pData = procedurePages?.[procedure];

  if (!pData) {
    return <NotFound />;
  }

  procedureTitle =
    pData.procedures.subtitle ||
    `${titleCase(procedure.replaceAll("-", " "))} Procedures`;

  return (
    <>
      <Banner
        title={pData.banner.title}
        subtitle={pData.banner.subtitle}
        imageSrc={pData.banner.image}
        reverseTitle={pData.banner.reverseTitle}
      />

      <Box sx={{ "> *": { mb: [2, 6] } }}>
        <Typography px={[3, 16]} variant="h1">
          Education Library
        </Typography>
        <Procedures
          title={procedureTitle}
          procedureItems={pData.procedures.phases.map((p) => p.title)}
          paragraphs={pData.procedures.paragraphs}
          isGeneral={procedure === "general"}
          proceduresImg={pData.procedures.image}
          proceduresImgAlt={pData.procedures.imageAlt}
        />
        {pData.procedures.phases.map(
          ({ title, slug, image, imageAlt, subphases, paragraphs }, idx) => (
            <EducationCategory
              key={title}
              title={title}
              subphases={subphases}
              procedureSlug={slug}
              imageSrc={image}
              imageAlt={imageAlt}
              isImageFirst={idx % 2 === 0}
              darkBg={idx % 2 === 0}
              isGeneral={procedure === "general"}
              paragraphs={paragraphs}
            />
          )
        )}
      </Box>
      <MemberPopUp />
      <ProcedureModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default ProcedurePage;
