import { Typography, styled } from "@mui/material";

// Alternative Typography component that matches the h3
// heading styling without using actual h3 components
const TypographyT3 = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 400,
  color: theme.palette.text.dark,
  [theme.breakpoints.down("lg")]: {
    fontSize: 22,
  },
}));

export default TypographyT3;
