import { useNavigate } from "react-router-dom";
import { NavBar } from "./Navbar.styles";
import Image from "../../../components/Image/Image";
import BlueButton from "../../../components/Basic/BlueButton/BlueButton";
import TypographyT5 from "../../../components/Basic/Typography/TypographyT5";
import { useNavbar } from "../../../contexts/NavbarProvider";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Link, Stack, useMediaQuery } from "@mui/material";
import IconButtonTooltip from "../../../components/IconButtonTooltip/IconButtonTooltip";
import { QuestionMarkRounded } from "@mui/icons-material";

const Navbar = forwardRef(({ procedurePage }, ref) => {
  const navigate = useNavigate();
  const { onProcedureModal } = useNavbar();
  const [totalHelpLinkClick, setTotalHelpLinkClick] = useState(0);
  const linkRef = useRef(null);

  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (totalHelpLinkClick === 2) {
      linkRef.current && linkRef.current.click();
      setTotalHelpLinkClick(0);
    }
  }, [totalHelpLinkClick, linkRef]);

  const handleHelpClick = (event) => {
    event.preventDefault();

    if (isTouchDevice) {
      if (totalHelpLinkClick !== 2) {
        setTotalHelpLinkClick((p) => p + 1);
      }
    } else {
      linkRef.current && linkRef.current.click();
    }
  };

  return (
    <NavBar ref={ref} procedurePage={procedurePage}>
      <Image
        logo
        width="auto"
        height={70}
        mdHeight={40}
        src="/static/img/logo.svg"
        alt="TurningPoint logo"
        onClick={() => navigate("/")}
        tabIndex={0}
      />
      <Stack direction={"row"} gap={2} justifyContent={"center"}>
        {procedurePage && (
          <BlueButton onClick={onProcedureModal}>
            <TypographyT5 color="inherit" paddingBottom={0}>
              Learn About a Different Topic
            </TypographyT5>
          </BlueButton>
        )}
        <IconButtonTooltip
          onClick={handleHelpClick}
          href={"mailto:myhealth@tpshealth.com"}
          tooltipTitle={
            <span>
              Are you having trouble gaining access to the website or is
              something not working correctly? Please select this button and
              email{" "}
              <Link
                ref={linkRef}
                sx={{ fontWeight: 700, color: "white" }}
                href="mailto:myhealth@tpshealth.com"
              >
                myhealth@tpshealth.com
              </Link>{" "}
              with your issue and someone on our team will help!
            </span>
          }
          Icon={QuestionMarkRounded}
        >
          <TypographyT5 color="inherit" paddingBottom={0}>
            Need Help
          </TypographyT5>
        </IconButtonTooltip>
      </Stack>
    </NavBar>
  );
});

export default Navbar;
