import { Grid, Typography } from "@mui/material";
import ArticleBox from "../../../components/ArticleBox/ArticleBox";
import { articleSummary } from "../../../data/articleSummary";
import { education } from "../../../data/education";

const ArticleOverview = ({
  slug,
  subphases = [],
  scrollTop = false,
  isArticlePage = false,
  title = "Learn About Your Procedure",
  procedureSlug,
}) => {
  let stepsItems = [];

  if (subphases.length > 0) {
    stepsItems = isArticlePage ? subphases : subphases.slice(1);
  }

  // ex. landing page specific:
  if (!stepsItems?.length) {
    const itemCategory = Object.keys(education).find(
      (key) => education[key].slug === slug
    );
    stepsItems = articleSummary[itemCategory];
  }

  const listSize = stepsItems?.length;

  return (
    <Grid container direction="column" gap={2} my={2}>
      <Grid item>
        <Typography variant="h3"> {title} </Typography>
      </Grid>
      <Grid item display="flex">
        <Grid container spacing={2}>
          {stepsItems?.map((item) => (
            <ArticleBox
              key={item.title}
              item={item}
              size={listSize}
              scrollTop={scrollTop}
              procedureSlug={procedureSlug}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArticleOverview;
