import { Box, Grid } from "@mui/material";
import ArticleBanner from "../ArticleBanner/ArticleBanner";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { extendExpiry } from "../../../utils/storage";
import { useEffect, useMemo } from "react";
import { sessionLength } from "../../../data/constants";
import { procedurePages } from "../../../data/procedures";
import NotFound from "../../NotFound";
import MemberPopUp from "../../../components/MemberPopUp/MemberPopUp";
import FeedbackModal from "../../../components/FeedbackModal/FeedbackModal";
import ArticleAccordion from "../ArticleAccordion/ArticleAccordion";
import log from "../../../api/log";

const GeneralHealthArticle = ({ procedureSlug, categorySlug }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isExtended = extendExpiry("token", sessionLength);
    isExtended && log.session("information", {message: "Session extended", success: true})
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [categorySlug]);

  const currentPhase = useMemo(
    () =>
      Object.entries(procedurePages).reduce(
        (acc, [pName, procedure]) =>
          procedure.procedures.phases.find(
            (phase) => phase.slug === categorySlug
          ) || acc,
        {}
      ),
    [categorySlug]
  );

  if (!currentPhase.hasOwnProperty("title")) {
    return <NotFound />;
  }

  const onBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      const url = `/procedures/${procedureSlug}`;
      navigate(url, { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  const subphases = currentPhase.subphases;
  let bannerTitle = currentPhase.title;
  return (
    <>
      <ArticleBanner title={bannerTitle} />
      <Breadcrumbs isBack onClickBreadcrumb={onBack} />
      <Box sx={{ "> *": { mb: [2, 6] } }}>
        <Grid container direction="column" gap={2} px={[3, 16]} my={[2, 3]}>
          {subphases.map((sub) => (
            <ArticleAccordion subphase={sub} procedureSlug={procedureSlug} />
          ))}
        </Grid>
      </Box>
      <MemberPopUp />
      <FeedbackModal procedure={"general"} topic={categorySlug} />
    </>
  );
};

export default GeneralHealthArticle;
