import { Container, Grid, Typography } from "@mui/material";
import useLogVideo from "../../../hooks/useLogVideo";
import { useRef } from "react";

const ArticleContent = ({ content, title = "" }) => {
  const html = content.replaceAll("h3", "h5");
  const contentRef = useRef(null);

  useLogVideo(contentRef);

  return (
    <Grid container direction="column" gap={2} px={[3, 16]} my={[2, 3]}>
      <Typography variant="h2" fontSize={40} mb={-3}>
        {title}
      </Typography>
      <Container disableGutters maxWidth="auto">
        <div
          ref={contentRef}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </Container>
    </Grid>
  );
};

export default ArticleContent;
