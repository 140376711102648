import AnkleWristWoman from "../assets/img/ankle-wrist/ankle-wrist-woman.jpg";
import AnkleWristBanner from "../assets/img/ankle-wrist/ankle-wrist-banner.jpg";
import PersonHoldingWrist from "../assets/img/ankle-wrist/person-holding-wrist.jpg";
import WristDoctorPatient from "../assets/img/ankle-wrist/wrist-doctor-patient.jpg";
import PersonNikeSock from "../assets/img/ankle-wrist/person-nike-sock.jpg";
import SpineBanner from "../assets/img/spine/spine-banner.jpg";
import WomanHandsBack from "../assets/img/spine/woman-hands-back.jpg";
import SpineDiagram from "../assets/img/spine/spine-diagram.jpg";
import KneeBanner from "../assets/img/knee/knee-banner.jpg";
import KneeImage from "../assets/img/knee/knees.jpg";
import BasketballKneeBrace from "../assets/img/knee/basketball-knee-brace.jpg";
import ManStretching from "../assets/img/hip/man-stretching.jpeg";
import WomanLunge from "../assets/img/hip/woman-lunge.jpg";
import HipBanner from "../assets/img/hip/hip-banner.jpg";
import WomanMeditating from "../assets/img/heart-procedures/woman-meditating.jpg";
import HeartDiagramProcedure from "../assets/img/heart-procedures/heart-diagram-procedure.jpg";
import HeartProceduresBanner from "../assets/img/heart-procedures/heart-procedures-banner.jpg";
import ManRunning from "../assets/img/heart-monitoring/man-running.jpg";
import HeartDiagramMonitoring from "../assets/img/heart-monitoring/heart-diagram-monitoring.jpg";
import HeartMonitorBanner from "../assets/img/heart-monitoring/heart-monitor-banner.jpg";
import Books from "../assets/img/general-health/books.jpg";
import DoctorPhone from "../assets/img/general-health/doctor-phone.jpg";
import GeneralHealthBanner from "../assets/img/general-health/general-health-banner.jpg";
import WomanShoulder from "../assets/img/shoulder/woman-shoulder.jpg";
import ManShoulder from "../assets/img/shoulder/man-shoulder.jpg";
import ShoulderBanner from "../assets/img/shoulder/shoulder-banner.jpg";

import {
  AnkleWristIcon,
  GeneralHealthIcon,
  HeartProceduresIcon,
  HipIcon,
  InternalHeartIcon,
  KneeIcon,
  ShoulderIcon,
  SpineIcon,
} from "../assets/icons";

// only used in old landing page, leaving for now, evaluate if it should be removed later
export const jointAndSpineProcedureParagraphs = [
  "Joint procedures are commonly performed to address issues in the body's major joints, such as the knee, hip, shoulder, and spine. They can range from minimally invasive arthroscopy to full joint replacement surgeries.",
  "Arthroscopic procedures involve small incisions and the use of specialized instruments to diagnose and treat problems within the joint, such as removing damaged tissue or repairing ligaments. Joint replacements, on the other hand, involve replacing a damaged joint with an artificial implant. These procedures are often recommended for conditions like osteoarthritis, rheumatoid arthritis, or joint injuries.",
];

export const procedureItems = [
  { Icon: HipIcon, label: "hip", slug: "hip" },
  { Icon: SpineIcon, label: "spine", slug: "spine" },
  { Icon: ShoulderIcon, label: "shoulder", slug: "shoulder" },
  { Icon: KneeIcon, label: "knee", slug: "knee" },
  { Icon: AnkleWristIcon, label: "ankle / wrist", slug: "ankle-wrist" },
  {
    Icon: InternalHeartIcon,
    label: "heart monitoring",
    slug: "heart-monitoring",
  },
  {
    Icon: HeartProceduresIcon,
    label: "heart procedures",
    slug: "heart",
  },
  {
    Icon: GeneralHealthIcon,
    label: "General Health Information",
    slug: "general",
  },
];

export const procedurePages = {
  knee: {
    slug: "knee",
    banner: { image: KneeBanner, title: "Your Knee", reverseTitle: true },
    procedures: {
      subtitle: "Knee Procedures",
      image: KneeImage,
      imageAlt: "person with one bent and one straightend knee lying on bed",
      paragraphs: [
        "Having surgery comes with a lot of feelings. To help you feel prepared, select your procedure below to learn more about before, during, and after your surgery. Our goal is to give you the tools you need for a great procedure to feel your best.",
        "Before Surgery – Prepare for your surgery by following these key steps. Knowing what to expect can help you feel more at ease.",
        "After Surgery – Heal smoothly with these helpful tips. Following the right steps after surgery can speed up your healing.",
        "Frequently Asked Questions – Learn the basics of your surgery and see questions from others in the same place as you.",
        "Put the worrying behind you by finding your surgery below and going over the content our medical experts have chosen  just for you.",
      ],
      phases: [
        {
          id: "",
          title: "Knee Replacement (Total,Primary)",
          slug: "knee_replacement_total",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "abu4886-abn1940-abu4878-abu4876-abu4888-ace8875-abu4887-abu4883-abp6394",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A total knee replacement replaces the worn ends of the bones where they meet at the knee. Those bones are the thighbone (femur) and the lower leg bone (tibia). Your doctor will remove the damaged bone. Then your doctor will replace it with plastic and metal parts. These new parts may be attached to your bones with cement.",
              ],
              isVideo: false,
            },
            {
              id: "abu1275-aci3126-abq5098-abu4875-abp8385",
              title: "After Surgery",
              slug: "after",
              summary:
                "When you leave the hospital, you should be able to move around with a walker or crutches. But you will need someone to help you at home until you have more energy and can move around better…",
            },
            {
              id: "ach8720-ach8726-ack8556-ach8731-aci3115-aci3141-aci3154-aci3135-aci3151-abu4882-aci3118-abu1272",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Knee Arthroscopy",
          slug: "knee_arthroscopy",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "abu4743-abp4901-abu4747-abu4746-tp13050",
              title: "Before surgery",
              slug: "before",
              paragraphs: [
                "Arthroscopy is a way to find problems and do surgery inside a joint without making a large cut (incision). Your doctor puts a lighted tube with a tiny camera and surgical tools through small incisions in your knee. The camera is called an arthroscope, or scope.",
              ],
            },
            {
              id: "abu4742-abp4871-abu4740",
              title: "After surgery",
              slug: "after",
              summary:
                "You will feel tired for several days. Your knee will be swollen. And you may notice that your skin is a different color near the cuts (incisions). The swelling is normal and will start to go away in a few days. Keeping your leg higher than your heart will help with swelling…",
            },
            {
              id: "abu4739-ace9050-tp13050",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "ACL Repair",
          slug: "acl_repair",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "acf0728-abp4877-abt6118-abt6117",
              title: "Before surgery",
              slug: "before",
              paragraphs: [
                'The anterior cruciate ligament (ACL) is one of four knee ligaments that connect the upper leg bone (femur) with the lower leg bone (tibia). When you injure or tear your ACL, your knee is less stable. It may "give out" when you do not expect it and then become stiff and swollen.',
              ],
            },
            {
              id: "abt6080-abt6079-acf0729-acf0723",
              title: "After surgery",
              slug: "after",
              summary:
                "You will feel tired for several days. Your knee will be swollen. And you may have numbness around the cut (incision) on your knee. Your ankle and shin may be bruised or swollen. You can put ice on the area to reduce swelling. Most of this will go away in a few…",
            },
            {
              id: "hw139757-acf0730-acf0732-acf0731-ace0901",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Knee Replacement (Revision)",
          slug: "knee_replacement_revision",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "ace8872-abq5084-zm2516-acl2171-acl2174",
              title: "Before surgery",
              slug: "before",
              paragraphs: [
                "Joint replacement involves surgery to replace the ends of bones in a damaged joint. This surgery creates new joint surfaces.",
              ],
            },
            {
              id: "abq5098-acg9453-abt4914",
              title: "After surgery",
              slug: "after",
              summaryParagraphs: [
                "Call your doctor now if:",
                "•	You have symptoms of infection, such as:",
                "o	Increased pain, swelling, warmth, or redness…",
              ],
            },
            {
              id: "acl2171-acl2174",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Knee Replacement (Partial)",
          slug: "knee_replacement_partial",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "ace8872-abn1940-abu5535-abq5084-ace8876-acl2171-abp6394",
              title: "Before surgery",
              slug: "before",
              paragraphs: [
                "Joint replacement involves surgery to replace the ends of bones in a damaged joint. This surgery creates new joint surfaces.",
              ],
            },
            {
              id: "abu5538-abq5098-abu5536-acd9477",
              title: "After surgery",
              slug: "after",
              summary:
                "When you leave the hospital, you should be able to walk with a cane, crutches, or a walker. But you will need someone to help you at home until you have more energy and can move around better.",
            },
            {
              id: "abu5531-acl2174",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Meniscal Repair",
          slug: "meniscal_repair",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "acf2416-abp4889-abu4664",
              title: "Before surgery",
              slug: "before",
              paragraphs: [
                "A meniscus tear is a common injury to the cartilage that stabilizes and cushions the knee joint. Whether or not your tear can be repaired depends on the type of the tear. Radial tears sometimes can be repaired, depending on where they are located. Horizontal, flap, long-standing, and degenerative tears—those caused by years of wear and tear—generally can't be repaired.",
              ],
            },
            {
              id: "abu4659-abu4658-acf2417",
              title: "After surgery",
              slug: "after",
              summary:
                "You will feel tired for several days. Your knee will be swollen. And you may have numbness around the cuts the doctor made (incisions) on your knee. You can put ice on the knee to reduce swelling. Most of this will go away in a few days. You should soon…",
            },
            {
              id: "stm159382-acf2418-acf2419-acg1421",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Osteochondral Defect Repair",
          slug: "osteochondral_defect_repair",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "acd8481-aci9049-acd8450",
              title: "Before surgery",
              slug: "before",
              paragraphs: [
                "Before your surgery, your surgeon or nurse will remind you to do these things:",
                "•	Follow the instructions exactly about when to stop eating and drinking. If your doctor has told you to take medicines on the day of surgery, do so using only a sip of water.",
                "•	Do not use aspirin or other nonsteroidal anti-inflammatory medicines (NSAIDs) for 1 week or as instructed before your surgery.",
                "•	If you are having same-day surgery, arrange for someone to take you home.",
                "•	If you will need it, make plans for someone to stay with you for the first 24 hours.",
                "•	Shower before surgery as instructed. Don't use lotions, perfumes, or deodorants.",
                "•	Leave all valuables, such as money and jewelry, at home.",
                "•	Remove all nail polish and body jewelry, such as piercings.",
              ],
            },
            {
              id: "abp9106-acd8316-acd8345",
              title: "After surgery",
              slug: "after",
              summary:
                "The most common problems right after surgery are pneumonia, bleeding, infection, bruising or blood clotting (hematoma) at the surgery site, trouble urinating, and reactions to the anesthesia…",
            },
            {
              id: "acd8313-acd8357-acg9453",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Meniscal Allograft Transplantation",
          slug: "meniscal_allograft_transplantation",
          image: BasketballKneeBrace,
          imageAlt: "woman drinking and in a knee brace",
          subphases: [
            {
              id: "acd8481-aci9049-acd8450",
              title: "Before surgery",
              slug: "before",
              paragraphs: [
                "Follow the instructions about when to stop eating, drinking, and taking aspirin or other nonsteroidal anti-inflammatory medicines (NSAIDs) or other medicines. Leave valuables at home. Bring your insurance information and personal items you'll need. You may need someone to take you home and, if needed, stay with you for the first 24 hours.",
              ],
            },
            {
              id: "abp9106-acd8316-acd8345",
              title: "After surgery",
              slug: "after",
              summary:
                "In the first 48 hours after surgery, the most likely risks are bleeding and problems with your heart or lungs. From 48 hours to 30 days after surgery, the most common risks are infection, blood clots, and problems with other body organs, such as a urinary tract.",
            },
            {
              id: "acd8313-acd8357-acg9453",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
      ],
    },
  },

  hip: {
    slug: "hip",
    banner: { image: HipBanner, title: "Your Hip", reverseTitle: true },
    procedures: {
      slug: "hip",
      subtitle: "Hip Procedures",
      image: ManStretching,
      imageAlt: "man hamstring stretching",
      paragraphs: [
        "Having surgery comes with a lot of feelings. To help you feel prepared, select your procedure below to learn more about before, during, and after your surgery. Our goal is to give you the tools you need for a great procedure to feel your best.",
        "Before Surgery – Prepare for your surgery by following these key steps. Knowing what to expect can help you feel more at ease.",
        "After Surgery – Heal smoothly with these helpful tips. Following the right steps after surgery can speed up your healing.",
        "Frequently Asked Questions – Learn the basics of your surgery and see questions from others in the same place as you.",
        "Put the worrying behind you by finding your surgery below and going over the content our medical experts have chosen  just for you.",
      ],
      phases: [
        {
          id: "",
          title: "Hip Replacement (Total,Primary)",
          slug: "hip_replacement_total_primary",
          image: WomanLunge,
          imageAlt:
            "woman wearing gray shirt and orange leggings doing a lunge",
          subphases: [
            {
              id: "abu1261-abu1255-abn1949-abu1256-abu1262",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Hip replacement surgery uses metal, ceramic, or plastic parts to replace the ball at the top of the thighbone (femur). In a total hip replacement, the doctor also replaces the hip socket. In a partial hip replacement, the socket is not replaced.",
              ],
            },
            {
              id: "abt1463-abu1271-ack9500-ace8868-abp6771-abp8378-acl2182",
              title: "After Surgery",
              slug: "after",
              summary:
                "Hip replacement surgery replaces the worn parts of your hip joint. After surgery, you will use crutches or a walker. You will need someone to help you at home for a few days or weeks or until you have more energy and can move around better.",
            },
            {
              id: "ack8564-abu6826-acc7810-acl2513-ace8870",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Hip Replacement (Revision)",
          slug: "hip_replacement_revision",
          image: WomanLunge,
          imageAlt:
            "woman wearing gray shirt and orange leggings doing a lunge",
          subphases: [
            {
              id: "abu1261-abn1949-abu1268-abu1262-abu1260",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Hip replacement surgery uses metal, ceramic, or plastic parts to replace the ball at the top of the thighbone (femur). In a total hip replacement, the doctor also replaces the hip socket. In a partial hip replacement, the socket is not replaced.",
              ],
            },
            {
              id: "ack9500-abt1463-abu1271-abu1267-abp6771-acl2501",
              title: "After Surgery",
              slug: "after",
              summary:
                "Your pain will be controlled with intravenous (I.V.) medicine. You will probably also have medicines to prevent infection, blood clots, and nausea. If you had regional anesthesia, you may have little or no feeling below your waist for a while.",
            },
            {
              id: "abu6826-acc7810-acl2513",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Hip Arthroscopy",
          slug: "hip_arthroscopy",
          image: WomanLunge,
          imageAlt:
            "woman wearing gray shirt and orange leggings doing a lunge",
          subphases: [
            {
              id: "abt1320-abt1323-abt1322",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Arthroscopy is a way to find problems  and do surgery inside a joint without making a large cut (incision). Your doctor puts a lighted tube with a tiny camera and surgical tools through small incisions in the side of your hip. The camera is called an arthroscope, or scope.",
              ],
            },
            {
              id: "abt1319-abt1318-abt1317",
              title: "After Surgery",
              slug: "after",
              summary:
                "Arthroscopy is a way to find problems and do surgery inside a joint without making a large cut (incision). Your doctor put a lighted tube with a tiny camera—called an arthroscope, or scope—and surgical tools through small incisions on the side of your hip.",
            },
            {
              id: "sta123325-ace9050-tp13050",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Femoroacetabular Arthroscopy",
          slug: "femoroacetabular_arthroscopy",
          image: WomanLunge,
          imageAlt:
            "woman wearing gray shirt and orange leggings doing a lunge",
          subphases: [
            {
              id: "abt1320-ace9807-abt1323-ace9051-abt1322",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Arthroscopy is a way to find problems and do surgery inside a joint without making a large cut (incision). Your doctor puts a lighted tube with a tiny camera and surgical tools through small incisions in the side of your hip. The camera is called an arthroscope, or scope.",
              ],
            },
            {
              id: "abt1319-abt1318-abt1317",
              title: "After Surgery",
              slug: "after",
              summary:
                "You will feel tired for several days. Your hip will be swollen. And you may notice that your skin is a different color near the incisions. The swelling is normal. It will start to go away in a few days.",
            },
            {
              id: "abt4912-acd8348-ace9050",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Hip Resurfacing",
          slug: "hip_resurfacing",
          image: WomanLunge,
          imageAlt:
            "woman wearing gray shirt and orange leggings doing a lunge",
          subphases: [
            {
              id: "acd5887-acd8450-aci9049",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Hip resurfacing replaces the damaged outer surfaces of the femoral head (the top of the thighbone) and, if needed, the cup-shaped socket where the thighbone meets the pelvis. This procedure removes less bone than hip replacement and maintains a better ball and socket joint. It may be done in younger people who have hip arthritis.",
              ],
            },
            {
              id: "abt4912-acd8316-acd8345",
              title: "After Surgery",
              slug: "after",
              summary:
                "The most common problems right after surgery are pneumonia, bleeding, infection, bruising or blood clotting (hematoma) at the surgery site, trouble urinating, and reactions to the anesthesia.",
            },
            {
              id: "tp10193-acd8313-acd5887-acd8485",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
      ],
    },
  },

  spine: {
    slug: "spine",
    banner: { image: SpineBanner, title: "Your Spine", reverseTitle: false },
    procedures: {
      subtitle: "Spine Procedures",
      image: SpineDiagram,
      imageAlt: "lower anatomical spine figure",
      paragraphs: [
        "Having surgery comes with a lot of feelings. To help you feel prepared, select your procedure below to learn more about before, during, and after your surgery. Our goal is to give you the tools you need for a great procedure to feel your best.",
        "Before Surgery – Prepare for your surgery by following these key steps. Knowing what to expect can help you feel more at ease.",
        "After Surgery – Heal smoothly with these helpful tips. Following the right steps after surgery can speed up your healing.",
        "Frequently Asked Questions – Learn the basics of your surgery and see questions from others in the same place as you.",
        "Put the worrying behind you by finding your surgery below and going over the content our medical experts have chosen  just for you.",
      ],
      phases: [
        {
          id: "",
          title: "Disc Replacement, Cervical",
          slug: "disc_replacement_cervical",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "acd8481-aci9049-acd8450",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Follow the instructions about when to stop eating, drinking, and taking aspirin or other nonsteroidal anti-inflammatory medicines (NSAIDs) or other medicines. Leave valuables at home. Bring your insurance information and personal items you'll need. You may need someone to take you home and, if needed, stay with you for the first 24 hours.",
              ],
            },
            {
              id: "abp9106-acd8316-acd8345",
              title: "After Surgery",
              slug: "after",
              summary:
                "The most common problems right after surgery are pneumonia, bleeding, infection, bruising or blood clotting (hematoma) at the surgery site, trouble urinating, and reactions to the anesthesia.",
            },
            {
              id: "acd8313-acd8357-acg9453",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Disc Replacement, Lumbar",
          slug: "disc_replacement_lumbar",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "acd8481-aci9049-acd8450",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Follow the instructions about when to stop eating, drinking, and taking aspirin or other nonsteroidal anti-inflammatory medicines (NSAIDs) or other medicines. Leave valuables at home. Bring your insurance information and personal items you'll need. You may need someone to take you home and, if needed, stay with you for the first 24 hours.",
              ],
            },
            {
              id: "abp9106-acd8316-acd8345",
              title: "After Surgery",
              slug: "after",
              summary:
                "In the first 48 hours after surgery, the most likely risks are bleeding and problems with your heart or lungs. From 48 hours to 30 days after surgery, the most common risks are infection, blood clots, and problems with other body organs, such as a urinary tract infection.",
            },
            {
              id: "tp10674-acd8313-acd8357-acg9453",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Implantable Pain Pump, Permanent",
          slug: "implantable_pain_pump_permanent",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abs9827-acl1656-abs9826-acl1653-acl1674-acl1655",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A continuous infusion pain pump is a device that sends numbing medicine to decrease pain. It is usually used to reduce pain in the area where you had surgery.",
                "A small tube (catheter) may be placed into or near where the doctor cut your skin (your incision). Or the doctor may place the catheter near a group of nerves that supply feeling to the area where you had surgery. The tube connects to the pump outside your body, and a small pouch inside the pump holds the medicine. The pump is set to give you a steady flow of medicine for several days after surgery to help control your pain.",
              ],
            },
            {
              id: "abs9825",
              title: "After Surgery",
              slug: "after",
              summaryParagraphs: [
                "Call your doctor now or seek immediate medical care if:",
                "•	Your pain gets worse.",
                "•	Your pain is not controlled by the medicine…",
              ],
            },
            {
              id: "acl1699-acl1729-acl1654-acl1727-acl1738",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Implantable Pain Pump, Reprogram/Refill",
          slug: "implantable_pain_pump_reprogram_refill",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abs9827-acl1656-abs9826-acl1653-acl1674-acl1655",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A continuous infusion pain pump is a device that sends numbing medicine to decrease pain. It is usually used to reduce pain in the area where you had surgery.",
                "A small tube (catheter) may be placed into or near where the doctor cut your skin… (your incision). Or the doctor may place the catheter near a group of nerves that supply feeling to the area where you had surgery. The tube connects to the pump outside your body, and a small pouch inside the pump holds the medicine. The pump is set to give you a steady flow of medicine for several days after surgery to help control your pain.",
              ],
            },
            {
              id: "abs9825",
              title: "After Surgery",
              slug: "after",
              summaryParagraphs: [
                "Call your doctor now or seek immediate medical care if:",
                "•	Your pain gets worse.",
                "•	Your pain is not controlled by the medicine…",
              ],
            },
            {
              id: "acl1699-acl1729-acl1654-acl1727-acl1738",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Implantable Pain Pump, Revision/Removal",
          slug: "implantable_pain_pump_revision_removal",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abs9827-acl1656-abs9826-acl1653-acl1674-acl1655",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A continuous infusion pain pump is a device that sends numbing medicine to decrease pain. It is usually used to reduce pain in the area where you had surgery.",
                "A small tube (catheter) may be placed into or near where the doctor cut your skin (your incision). Or the doctor may place the catheter near a group of nerves that supply feeling to the area where you had surgery. The tube connects to the pump outside your body, and a small pouch inside the pump holds the medicine. The pump is set to give you a steady flow of medicine for several days after surgery to help control your pain.",
              ],
            },
            {
              id: "abs9825",
              title: "After Surgery",
              slug: "after",
              summaryParagraphs: [
                "Call your doctor now or seek immediate medical care if:",
                "•	Your pain gets worse.",
                "•	Your pain is not controlled by the medicine….",
              ],
            },
            {
              id: "acl1699-acl1729-acl1654-acl1727-acl1738",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Kyphoplasty & Vertebroplasty",
          slug: "kyphoplasty_vertebroplasty",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abt3026-acd6614-abt3028-abt3027-abt3036-abt3034",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                `Kyphoplasty (say "KY-foh-plas-tee") is a procedure for your back. It is done to relieve pain from compression fractures of the spine. It can return your vertebrae to a more normal shape.`,
                "Your doctor may numb the area, or you may get medicine to make you sleep. The doctor makes a small cut in your back. Then the doctor puts a balloon device into a vertebra. The doctor inflates the balloon and then deflates it. Then a type of cement is put into the space created by the balloon.",
              ],
            },
            {
              id: "abt3032-abt3031-abt3039-abt3038-abt3030",
              title: "After Surgery",
              slug: "after",
              summary:
                "After kyphoplasty to relieve pain from compression fractures, your back may feel sore where the hollow needle (trocar) went into your back. This should go away in a few days. Most people are able to return to their daily activities within a day.",
            },
            {
              id: "acd6612-acg3314-acd6613",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Laminectomy/Discectomy, Cervical",
          slug: "laminectomy_discectomy_cervical",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abu4565-abt1802-abu4569-abt1806-abu4568-abt1805",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A cervical discectomy is surgery to take out damaged tissue from the discs in the neck area of the spine. You might also have bone growths (spurs) pressing on the nerves. The surgery takes pressure off the nerves.",
                "The doctor will take out tissue through a small cut in your neck. This cut is called an incision. It may be on the front of your neck, or it may be along your spine on the back of your neck. If the incision is at the front of your neck, your doctor will put in a small piece of bone between the vertebrae. Small plates and screws may be used to keep the bones in place. This is called fusion. If the incision is at the back of your neck, the extra piece of bone often isn't needed.",
              ],
            },
            {
              id: "abu4564-abt1810-abu4562-abt1808-abt1807",
              title: "After Surgery",
              slug: "after",
              summary:
                "The cervical discectomy took out damaged tissue from the discs in the neck area of your spine. The surgery took pressure off your nerves. You can expect your neck to feel stiff or sore. This should improve in the weeks after…",
            },
            {
              id: "",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Laminectomy/Discectomy, Lumbar",
          slug: "laminectomy_discectomy_lumbar",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abt4334-acf0249-abo7528-ace8827-abt4336-abt4335",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A lumbar laminectomy is surgery to ease pressure on the spinal cord and/or nerves of the lower spine. This is also called decompression surgery. The doctor makes a cut in the lower back. This cut is called an incision. Then the doctor takes out pieces of bone that are squeezing the spinal cord and nerves. The doctor may also take out other tissues.",
              ],
            },
            {
              id: "abt4339-acm1473-acf0250-abt4338-abt4489-abt4337",
              title: "After Surgery",
              slug: "after",
              summary:
                "A lumbar laminectomy is surgery to ease pressure on the spinal cord and nerves of the lower spine. The doctor took out pieces of bone that were squeezing the spinal cord and nerves.",
            },
            {
              id: "ace8829-uh2002-stl158229-acf0253",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Laminectomy/Discectomy, Thoracic",
          slug: "laminectomy_discectomy_thoracic",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "stl158229-abu4570",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Laminectomy and laminotomy are surgeries done to relieve pressure on the spinal cord and/or spinal nerve roots by removing all or part of the lamina. The lamina, the thin part of the bones that make up the spine (vertebrae), protects the spinal cord.",
                "Age-related changes in the spine may narrow the opening through which the spinal cord runs (spinal canal), and the spinal cord and/or nerve roots may become squeezed. Laminotomy removes part of the lamina. Laminectomy removes all of the lamina on selected vertebrae. It also may remove thickened ligament tissue. The choice of procedure depends on where the spinal problem is and how bad it is. Less pressure on the nerve roots often can relieve leg or arm pain and can allow you to resume normal daily activities.",
              ],
            },
            {
              id: "abp9106-acg9453-acd8316-acd8345",
              title: "After Surgery",
              slug: "after",
              summaryParagraphs: [
                "Call your doctor now if:",
                "•	You have symptoms of infection, such as:",
                "o	Increased pain, swelling, warmth, or redness…",
              ],
            },
            {
              id: "abu4572-acl0069",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Cord Neurostimulator, Permanent",
          slug: "spinal_cord_neurostimulator_permanent",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "acf1753-ace4380-ace4382-ace4385-acf1757-acf1756",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "This treatment targets the area of your body where you feel pain. There are different types of devices you may get.",
              ],
            },
            {
              id: "ace4383-acd8345-abt4912",
              title: "After Surgery",
              slug: "after",
              summary:
                "You may have a spinal cord stimulator for many years. It can help you live with much less pain, but you will have to learn how to use it. After the surgery, you and your doctor can figure out the best pulse strength. It may…",
            },
            {
              id: "ace4379-ace4381-acf1755-zm2325",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Cord Neurostimulator, Revision/Removal",
          slug: "spinal_cord_neurostimulator_revision_removal",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "acf1753-ace4380-ace4382-ace4385-acf1757-acf1756",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Electrical nerve stimulation is a procedure that uses an electrical current to treat chronic pain. Peripheral nerve stimulation (PNS) and spinal cord stimulation (SCS) are two types of electrical nerve stimulation. In either, a small pulse generator sends electrical pulses to the nerves (in peripheral nerve stimulation) or to the spinal cord (in spinal cord stimulation). These pulses interfere with the nerve impulses that make you feel pain.",
              ],
            },
            {
              id: "ace4383-acd8345-abt4912",
              title: "After Surgery",
              slug: "after",
              summary:
                "After the surgery, you and your doctor can figure out the best pulse strength. It may need to be adjusted a few times. Your doctor will show you how to use the stimulator at home. You may feel a tingle or some warmth while you use electrical nerve stimulation…",
            },
            {
              id: "ace4379-ace4381-acf1755-zm2325",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Cord Neurostimulator, Trial",
          slug: "spinal_cord_neurostimulator_trial",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "acf1753-ace4380-ace4382-ace4385-acf1757-acf1756",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Electrical nerve stimulation is a procedure that uses an electrical current to treat chronic pain. Peripheral nerve stimulation (PNS) and spinal cord stimulation (SCS) are two types of electrical nerve stimulation. In either, a small pulse generator sends electrical pulses to the nerves (in peripheral nerve stimulation) or to the spinal cord (in spinal cord stimulation). These pulses interfere with the nerve impulses that make you feel pain.",
              ],
            },
            {
              id: "ace4383-acd8345-abt4912",
              title: "After Surgery",
              slug: "after",
              summary:
                "After the surgery, you and your doctor can figure out the best pulse strength. It may need to be adjusted a few times. Your doctor will show you how to use the stimulator at home. You may feel a tingle or some warmth while you use electrical nerve stimulation…",
            },
            {
              id: "ace4379-ace4381-acf1755-zm2325",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Fusion, Cervical",
          slug: "spinal_fusion_cervical",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abu5414-abu5416-abu5415-sta123359",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Cervical spinal fusion is surgery that joins two or more of the vertebrae in your neck. When these bones are joined together, it's called fusion. After the joints are fused, they can no longer move.",
              ],
            },
            {
              id: "abu5419-abu5417-abt4231",
              title: "After Surgery",
              slug: "after",
              summary:
                "Cervical spinal fusion is surgery that joins two or more of the vertebrae in your neck. It made your neck more stable. After surgery, you can expect your neck to feel stiff and sore. This should improve in the..",
            },
            {
              id: "acd8348-abt4912-acd6469",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Fusion, Lumbar",
          slug: "spinal_fusion_lumbar",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "abu5404-abu5407-abu5406-acf1233-sta123359",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Spinal fusion is surgery that joins, or fuses, two or more vertebrae together. The joints will no longer be able to move. The surgery is also called arthrodesis.",
              ],
            },
            {
              id: "abu5411-abu5408-acf1230-abu5409",
              title: "After Surgery",
              slug: "after",
              summary:
                "After surgery, you can expect your back to feel stiff and sore. You may have trouble sitting or standing in one position for very long. It may take 4 to 6 weeks to get back to doing simple activities, such as light housework. It may take 6 months to a year for your back…",
            },
            {
              id: "acf1231-abt4912-acd8348",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Fusion, Sacroiliac",
          slug: "spinal_fusion_sacroiliac",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "sts15013-acl0070-sta123359",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Spinal fusion is a surgery that joins (fuses) two or more vertebrae together. Vertebrae are the 33 interlocking bones of the spinal column that are stacked on top of each other.",
              ],
            },
            {
              id: "acd8316-acp9139-abt4912",
              title: "After Surgery",
              slug: "after",
              summary:
                "The most common problems right after surgery are pneumonia, bleeding, infection, bruising or blood clotting (hematoma) at the surgery site, trouble urinating, and reactions to the anesthesia.",
            },
            {
              id: "zm2325-abt4912-acd8348",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Fusion, Scoliosis",
          slug: "spinal_fusion_scoliosis",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "acf1404-sts15013-acl0070-sta123359-acd8481",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Spinal fusion is a surgery that joins (fuses) two or more vertebrae together. Vertebrae are the 33 interlocking bones of the spinal column that are stacked on top of each other.",
              ],
            },
            {
              id: "abp9106-abt4912",
              title: "After Surgery",
              slug: "after",
              summary:
                "Anesthesia can have side effects. Two of the most unpleasant ones are nausea and constipation. Nausea will soon wear off. But constipation can leave you uncomfortable for several days…",
            },
            {
              id: "zm2325-acf1406-acf1407",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Spinal Fusion, Thoracic",
          slug: "spinal_fusion_thoracic",
          image: WomanHandsBack,
          imageAlt: "woman in grey tank top with hands being laid on her back",
          subphases: [
            {
              id: "sts15013-acl0070-sta123359-acd8481",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Spinal fusion is a surgery that joins (fuses) two or more vertebrae together. Vertebrae are the 33 interlocking bones of the spinal column that are stacked on top of each other.",
              ],
            },
            {
              id: "acd8345-abp9106-acd8316-abt4912-acg9453",
              title: "After Surgery",
              slug: "after",
              summary:
                "After surgery, you will most likely go home with a sheet of instructions. You'll learn who to contact if you have a problem. You may need to arrange for any care you will need when you go home. This may include…",
            },
            {
              id: "zm2325-acd8357-acd8348-abt4912",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
      ],
    },
  },

  shoulder: {
    slug: "shoulder",
    banner: {
      image: ShoulderBanner,
      title: "Your Shoulder",
      reverseTitle: false,
    },
    procedures: {
      subtitle: "Shoulder Procedures",
      image: ManShoulder,
      imageAlt: "topless man with tatto doing a shoulder stretch",
      paragraphs: [
        "Having surgery comes with a lot of feelings. To help you feel prepared, select your procedure below to learn more about before, during, and after your surgery. Our goal is to give you the tools you need for a great procedure to feel your best.",
        "Before Surgery – Prepare for your surgery by following these key steps. Knowing what to expect can help you feel more at ease.",
        "After Surgery – Heal smoothly with these helpful tips. Following the right steps after surgery can speed up your healing.",
        "Frequently Asked Questions – Learn the basics of your surgery and see questions from others in the same place as you.",
        "Put the worrying behind you by finding your surgery below and going over the content our medical experts have chosen  just for you.",
      ],
      phases: [
        {
          id: "",
          title: "Acromioplasty and Rotator Cuff Repair",
          slug: "acromioplasty_and_rotator_cuff_repair",
          image: WomanShoulder,
          imageAlt: "women's shoulder in black and white",
          subphases: [
            {
              id: "acf1381-abp6765-acf1373-abt6090-abx0680-abt6089",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Surgery may be used to treat a torn rotator cuff if the injury is very severe or if nonsurgical treatment has not improved shoulder strength and movement sufficiently.",
              ],
            },
            {
              id: "abt6094-abp6795-acf1382-abt6092-acf1374-abt6091",
              title: "After Surgery",
              slug: "after",
              summary:
                "You will feel tired for several days. Your shoulder will be swollen. And you may notice that your skin is a different color near the cut (incision). Your hand and arm may also be swollen. This is normal and will start to get better in a few days.",
            },
            {
              id: "acf1385-acg1264-acf1376-acf1383",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "shoulder-arthroscopy",
          title: "Shoulder Arthroscopy",
          slug: "shoulder_arthroscopy",
          image: WomanShoulder,
          imageAlt:
            "topless man raising his right hand, back and shoulder spread",
          subphases: [
            {
              id: "abu1283-abu1287-abu1286-abu1325-abu1329-abu1328",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Shoulder arthroscopy is a type of surgery. It lets a doctor repair shoulder problems without making a large cut (incision).",
              ],
            },
            {
              id: "abu1291-abu1289-abu1324-abu1322-abu1288",
              title: "After Surgery",
              slug: "after",
              summary:
                "Your arm may be in a sling. You will feel tired for several days. Your shoulder will be swollen. And you may notice that your skin is a different color near the cuts the doctor made (incisions). Your hand and arm may also be swollen. This is normal and will go…",
            },
            {
              id: "tp13050-ace9050-acd8313-acd8485",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Shoulder Replacement, Total",
          slug: "shoulder_replacement_total",
          image: WomanShoulder,
          imageAlt:
            "topless man raising his right hand, back and shoulder spread",
          subphases: [
            {
              id: "acf1886-abp6789-acf1887-abs9192-abs9191",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Joint replacement involves surgery to replace the ends of bones in a damaged joint. This surgery creates new joint surfaces.",
              ],
            },
            {
              id: "abs9196-abp6801-ack8813-ack8815-abs9194-abs9193",
              title: "After Surgery",
              slug: "after",
              summary:
                "Shoulder replacement surgery replaces the worn parts of your shoulder joint. When you leave the hospital, your arm will be in a sling. It will be helpful if there is someone to help you at home for the next few weeks or until you have more energy and can move…",
            },
            {
              id: "acf1888-acd8313-acd8348",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Shoulder Fusion",
          slug: "shoulder_fusion",
          image: WomanShoulder,
          imageAlt: "women's shoulder in black and white",
          subphases: [
            {
              id: "abu2329-acd8481-aci9049-acd8450-abt5499",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "You can hurt your shoulder by using it too much during an activity, such as fishing or baseball. It can also happen as part of the everyday wear and tear of getting older. Shoulder injuries can be slow to heal, but your shoulder should get better with time.",
              ],
            },
            {
              id: "abp9106-acd8316-acd8345",
              title: "After Surgery",
              slug: "after",
              summary:
                "The most common problems right after surgery are pneumonia, bleeding, infection, bruising or blood clotting (hematoma) at the surgery site, trouble urinating, and reactions to the anesthesia…",
            },
            {
              id: "acd8313-aa55625-acf2641-acd8357-acg9453",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
      ],
    },
  },

  "ankle-wrist": {
    slug: "ankle-wrist",
    banner: {
      image: AnkleWristBanner,
      title: "Your Ankle / Wrist",
      reverseTitle: false,
    },
    procedures: {
      subtitle: "Ankle / Wrist Procedures",
      image: PersonNikeSock,
      imageAlt: "person wearing white nike socks",
      paragraphs: [
        "Having surgery comes with a lot of feelings. To help you feel prepared, select your procedure below to learn more about before, during, and after your surgery. Our goal is to give you the tools you need for a great procedure to feel your best.",
        "Before Surgery – Prepare for your surgery by following these key steps. Knowing what to expect can help you feel more at ease.",
        "After Surgery – Heal smoothly with these helpful tips. Following the right steps after surgery can speed up your healing.",
        "Frequently Asked Questions – Learn the basics of your surgery and see questions from others in the same place as you.",
        "Put the worrying behind you by finding your surgery below and going over the content our medical experts have chosen  just for you.",
      ],
      phases: [
        {
          id: "",
          title: "Ankle Replacement (Total & Revision)",
          slug: "ankle_replacement_total_revision",
          image: PersonNikeSock,
          imageAlt: "person wearing white nike socks",
          subphases: [
            {
              id: "acd5787-acd5788-acd5789-aci9049",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Ankle replacement (total ankle arthroplasty) is major surgery to reduce ankle pain. A surgeon removes the lower part of the shinbone (tibia) and top of the highest bone on the foot (talus). The ankle is replaced with plastic or metal pieces. Sometimes part of only one bone is replaced.",
              ],
            },
            {
              id: "acd5947-acd6008-acd5946-abp9106",
              title: "After Surgery",
              slug: "after",
              summary:
                "You may have a cast, boot, or splint on your leg for about a month after surgery. You won't be able to put weight on the leg at first. You may use crutches, a walker, or a knee scooter while the cast or boot is on. Your doctor will tell you when you can start putting…",
            },
            {
              id: "acd6005-acd6007-acd6006-acd8331-zm2471-zm2472",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Ankle Fusion",
          slug: "ankle_fusion",
          image: AnkleWristWoman,
          imageAlt: "woman wearing blue pants standing on track during daytime",
          subphases: [
            {
              id: "abt0344-abt0346-abt0347-aci9049",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Ankle fusion is surgery that helps to relieve pain and make your ankle more stable. The doctor makes one or more cuts in the ankle to reach the ankle joint. These cuts are called incisions. The doctor uses a tool to remove the surface of the ankle joint. This prepares the joint for the next steps. Then the doctor uses screws or other hardware to align and hold the bones so they grow together (fuse). The incisions are closed with stitches or staples.",
              ],
            },
            {
              id: "abt0343-abt0342-abp9106-acd8348",
              title: "After Surgery",
              slug: "after",
              summary:
                "You had ankle fusion surgery to treat a painful, unstable ankle. When you leave the hospital, you will wear a cast or walking boot. And you will use crutches or a walker to keep your weight off your ankle.",
            },
            {
              id: "sta123359-zm2471-zm2472-abt6192-acd8331",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Wrist Arthroplasty",
          slug: "wrist_arthroplasty",
          image: PersonHoldingWrist,
          imageAlt: "person holding their right wrist with their opposite hand",
          subphases: [
            {
              id: "ace8935-acd8481-aci9049-acd8450",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Arthroplasty is surgery done to reconstruct or replace a diseased   joint. For rheumatoid arthritis, arthroplasty is done to restore function to a joint or correct a deformity. Bones in a joint can be reshaped. Or all or part of the joint can be replaced with metal, ceramic, or plastic parts.",
              ],
            },
            {
              id: "ace8936-abp9106-acd8316-acd8345",
              title: "After Surgery",
              slug: "after",
              summary:
                "The most common problems right after surgery are pneumonia, bleeding, infection, bruising or blood clotting (hematoma) at the surgery site, trouble urinating, and reactions to the anesthesia.",
            },
            {
              id: "ace8938-acd8313-acd8357-acg9453",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Wrist Fusion",
          slug: "wrist_fusion",
          image: WristDoctorPatient,
          imageAlt: "person in white coat holding silver and blue ring",
          subphases: [
            {
              id: "sta123359-stw5557-acd8357-aci9049-ace8937-ace8939",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Arthrodesis is a type of surgery that joins (fuses) 2 bones in a diseased joint so that the joint can no longer move. It may be done when pain and disability or instability from a diseased joint can no longer be managed with medicines, splints, and other methods.",
              ],
            },
            {
              id: "acd8331-abp9106-zm6001",
              title: "After Surgery",
              slug: "after",
              summary:
                "A wrist splint can be worn to support the wrist while holding it firm and still (immobilize it). A good wrist splint should: Be comfortable, washable, durable, and easy to remove…",
            },
            {
              id: "abt4912-acd8348-acg9453-acd8313-acd8357",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
      ],
    },
  },

  heart: {
    slug: "heart",
    banner: {
      image: HeartProceduresBanner,
      title: "Your Heart",
      reverseTitle: false,
    },
    procedures: {
      subtitle: "Heart Procedures",
      image: HeartDiagramProcedure,
      imageAlt: "selective focus photography of heart organ illustration",
      paragraphs: [
        "Having surgery comes with a lot of feelings. To help you feel prepared, select your procedure below to learn more about before, during, and after your surgery. Our goal is to give you the tools you need for a great procedure to feel your best.",
        "Before Surgery – Prepare for your surgery by following these key steps. Knowing what to expect can help you feel more at ease.",
        "After Surgery – Heal smoothly with these helpful tips. Following the right steps after surgery can speed up your healing.",
        "Frequently Asked Questions – Learn the basics of your surgery and see questions from others in the same place as you.",
        "Put the worrying behind you by finding your surgery below and going over the content our medical experts have chosen  just for you.",
      ],
      phases: [
        {
          id: "",
          title: "Peripheral Revascularization",
          slug: "peripheral_revascularization",
          image: WomanMeditating,
          imageAlt: "woman wearing black sports bra in meditation",
          subphases: [
            {
              id: "abt4646-abn2026-abt4647-abt4645-ace8813",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                `Peripheral artery angioplasty (say "puh-RIFF-er-rull AR-ter-ree ANN-jee-oh-plass-tee") is a procedure that widens narrowed arteries in the pelvis or legs. Your doctor used a tube called a catheter to find narrowed arteries in your pelvis or legs and then widened them.`,
              ],
            },
            {
              id: "abt4651-ace8811-abt4650-abt4649",
              title: "After Surgery",
              slug: "after",
              summary:
                "You may have a bruise or a small lump where the catheter was put in a blood vessel. The area may feel sore for a few days after the procedure. You can do light activities at home. But don't do anything strenuous until your doctor says it is okay. This may be for several…",
            },
            {
              id: "ace8812-acd8348-acd8357",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Coronary Artery Bypass Surgery",
          slug: "coronary_artery_bypass_surgery",
          image: WomanMeditating,
          imageAlt: "woman wearing black sports bra in meditation",
          subphases: [
            {
              id: "abt4550-abn1090-abt4555-zm2642-abt4560-ace9411-abt4554-ace9409",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Coronary artery bypass graft (CABG) is surgery to treat coronary artery disease. The surgery helps blood make a detour, or bypass, around one or more narrowed or blocked coronary arteries. Coronary arteries are the blood vessels that bring blood to the heart muscle. Your doctor did the surgery through a cut, called an incision, in your chest.",
              ],
            },
            {
              id: "abt4559-ace9408-abt4562-abt4557-ack8155-acf2764-ack8257",
              title: "After Surgery",
              slug: "after",
              summary:
                "You will feel tired and sore for the first few weeks after surgery. You may have some brief, sharp pains on either side of your chest. Your chest, shoulders, and upper back may ache. These symptoms usually get better after 4 to 6 weeks. The incision in your chest…",
            },
            {
              id: "ack8153-stc123752-ace9410-ack8151-ack8156-abt4563",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Valve Replacement",
          slug: "valve_replacement",
          image: WomanMeditating,
          imageAlt: "woman wearing black sports bra in meditation",
          subphases: [
            {
              id: "abs7839-abt4751-abs7861-abs7856-abt0374-abo5549-acd8972-acd9501-abs7844-abt4750-abs7858-abt0370-abt4756-acd9160-ace9189-abr7219-ace9190-ace9684-ace9348-abs7843-abt4749-abs7857-abt0372-ace9682",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "You have had surgery to replace your heart's aortic valve. Your doctor did the surgery through a cut, called an incision, in your chest.",
                "You will feel tired and sore for the first few weeks after surgery. You may have some brief, sharp pains on either side of your chest. Your chest, shoulders, and upper back may ache. The incision in your chest may be sore or swollen. These symptoms usually get better after 4 to 6 weeks.",
              ],
            },
            {
              id: "abs7849-abt4755-abs7853-abt0379-abs7847-abt4753-abs7851-abt0377-acl8061-abt4757-ace9681-abs7850-abt0376",
              title: "After Surgery",
              slug: "after",
              summary:
                "You have had surgery to replace your heart's aortic valve. Your doctor did the surgery through a cut, called an incision, in your chest. You will feel tired and sore for the first few weeks after surgery. You may have some…",
            },
            {
              id: "ace9186-abs7859-abs9973-acl0649-ace9683-ace9347-zm2557-stp16643-aco6031-ace9187-abs9970-abt4759-abs9971-ace9188-ace9346",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Coronary Angioplasty/Stenting",
          slug: "coronary_angioplasty_stenting",
          image: ManRunning,
          imageAlt: "man running on road near grass field",
          subphases: [
            {
              id: "abt5858-abn1438-abt5893-abt5859-abt5861-acd4936-acd4887-ace9421",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "Coronary angioplasty is a procedure to open a blocked or narrow coronary artery. Coronary arteries are the blood vessels that bring oxygen to the heart muscle. It may also be called percutaneous coronary intervention (PCI).",
              ],
            },
            {
              id: "abt5866-abt5894-ack9157-ace9418-abt5864-acf7959-acl1583-abt5863-acd4892",
              title: "After Surgery",
              slug: "after",
              summary:
                "Your groin or wrist may have a bruise and feel sore for a few days after the procedure. You can do light activities around the house. But don't do anything strenuous until your doctor says it is okay. This may be for several days.",
            },
            {
              id: "abt5892-zm2311-ace9419-tp10257-ace9420",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Non-Coronary Angioplasty/Stenting",
          slug: "non_coronary_angioplasty_stenting",
          image: WomanMeditating,
          imageAlt: "woman wearing black sports bra in meditation",
          subphases: [
            {
              id: "abt4646-sta123257-sts15241",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                `Peripheral artery angioplasty (say "puh-RIFF-er-rull AR-ter-ree ANN-jee-oh-plass-tee") is a procedure to treat peripheral arterial disease of the legs. The procedure widens narrowed arteries in the pelvis or legs. It can help blood flow better. This may decrease leg pain or help wounds heal better.`,
              ],
            },
            {
              id: "abp9106-acd8316-acd8345",
              title: "After Surgery",
              slug: "after",
              summary:
                "The most common problems right after surgery are pneumonia, bleeding, infection, bruising or blood clotting (hematoma) at the surgery site, trouble urinating, and reactions to the anesthesia.",
            },
            {
              id: "acd8348-acd8357-acd8331",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Percutaneous Left Atrial Appendage Occluder",
          slug: "percutaneous_left_atrial_appendage_occluder",
          image: WomanMeditating,
          imageAlt: "woman wearing black sports bra in meditation",
          subphases: [
            {
              id: "acl5001-aco3973-acl5049-acl5048",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "The left atrial appendage (LAA) is a small sac or pouch in the left atrium. The atrium is an upper chamber of the heart.",
                `When you have atrial fibrillation (say "AY-tree-uhl fih-bruh-LAY-shun"), a type of irregular heartbeat, the heart's upper chambers quiver, or fibrillate. This can lead to blood clots in the left atrium. Most of these clots form in the LAA, where the blood pools. If a clot moves out of the heart and travels to the brain, it may cause a stroke.`,
              ],
            },
            {
              id: "acl5009-aco3974-acl5072",
              title: "After Surgery",
              slug: "after",
              summary:
                "After the procedure, you will spend at least 1 night in the hospital. Your groin may have a bruise and feel sore for a few days. This is where the catheter was inserted into your blood vessel. You can do light activities around the house. But don't do anything…",
            },
            {
              id: "acd8348-acd8357-acd8331",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
      ],
    },
  },

  "heart-monitoring": {
    slug: "heart-monitoring",
    banner: {
      image: HeartMonitorBanner,
      title: "Your Heart",
      reverseTitle: false,
    },
    procedures: {
      subtitle: "Heart Monitoring Procedures",
      image: HeartDiagramMonitoring,
      imageAlt: "anatomical heart figurine",
      paragraphs: [
        "Having surgery comes with a lot of feelings. To help you feel prepared, select your procedure below to learn more about before, during, and after your surgery. Our goal is to give you the tools you need for a great procedure to feel your best.",
        "Before Surgery – Prepare for your surgery by following these key steps. Knowing what to expect can help you feel more at ease.",
        "After Surgery – Heal smoothly with these helpful tips. Following the right steps after surgery can speed up your healing.",
        "Frequently Asked Questions – Learn the basics of your surgery and see questions from others in the same place as you.",
        "Put the worrying behind you by finding your surgery below and going over the content our medical experts have chosen  just for you.",
      ],
      phases: [
        {
          id: "",
          title: "Internal Cardiac Monitoring",
          slug: "internal_cardiac_monitoring",
          image: ManRunning,
          imageAlt: "man running on road near grass field",
          subphases: [
            {
              id: "abt1947-abt1945-abt1943",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "An implantable heart monitor is a small device placed under the skin of your chest. It records the electrical signals from your heart. A monitor is used to look for irregular heartbeats. It can help your doctor find out what is causing your fainting, lightheadedness, or other symptoms. It also can help your doctor check to see if treatment for an irregular heartbeat is working.",
              ],
            },
            {
              id: "abt1946-abt1944-abp9106",
              title: "After Surgery",
              slug: "after",
              summary:
                "You will get a medical ID card with information about your heart monitor. Keep it with you at all times. Tell all of your doctors that you have this monitor. Some electric devices have a strong electromagnetic field. This field can keep the heart…",
            },
            {
              id: "ack7080-acd8348-acd8333",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Pacemaker",
          slug: "pacemaker",
          image: ManRunning,
          imageAlt: "man running on road near grass field",
          subphases: [
            {
              id: "abt1499-abp4999-abt4766-acg4732-zm2216-acd8154-acd7895",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A pacemaker is a small device. It sends out mild electrical signals that keep your heart beating normally. The signals are painless. It can help stop the dizziness, fainting, and shortness of breath caused by a slow or unsteady heartbeat.",
              ],
            },
            {
              id: "abt4762-abp5005-acf1674-abt4760-acd7903-acd7898-acd8222",
              title: "After Surgery",
              slug: "after",
              summary:
                "Your chest may be sore where the doctor made the cut. You also may have a bruise and mild swelling. These symptoms usually get better in 1 to 2 weeks. You may feel a hard ridge along the incision. This usually gets softer in the months after surgery. You may be…",
            },
            {
              id: "stp1189-acd8155-acf1675-acn0932-acd8223-acg4790-acg4789-acd7896-acd7900-acd7909",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Implantable Cardioverter Defibrillator",
          slug: "implantable_cardioverter_defibrillator",
          image: ManRunning,
          imageAlt: "man running on road near grass field",
          subphases: [
            {
              id: "ze1317-abt4768-abp4993-abs7817-aby2429-acd9322",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "An implantable cardioverter-defibrillator (ICD) is a small device that uses electrical pulses or shocks to help control abnormal heart rhythms, especially ones that can be life-threatening. An ICD is also known as an automatic implantable cardioverter-defibrillator (AICD).",
              ],
            },
            {
              id: "aby2430-abu2648-abp9106",
              title: "After Surgery",
              slug: "after",
              summaryParagraphs: [
                "Learn about your ICD.",
                "•	This can help reduce any anxiety you may feel.",
                "•	Know how it works, what it does, and how it keeps you safe.",
              ],
            },
            {
              id: "abu2647-zm6383-abs6078-abs6070",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Leadless Pacemaker",
          slug: "leadless_pacemaker",
          image: ManRunning,
          imageAlt: "man running on road near grass field",
          subphases: [
            {
              id: "acg4491-acg4393-acn0906",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A leadless pacemaker is a small, battery-powered device. It sends mild electrical signals to your heart. This keeps the heart beating normally. These signals are painless. The pacemaker can help stop the dizziness, fainting, and shortness of breath caused by a slow heart rate.",
              ],
            },
            {
              id: "abt4762-abp5005-acf1674-abt4760",
              title: "After Surgery",
              slug: "after",
              summary:
                "Your chest may be sore where the doctor made the cut. You also may have a bruise and mild swelling. These symptoms usually get better in 1 to 2 weeks. You may feel a hard ridge along the incision. This usually gets softer in the months after surgery. You may be...",
            },
            {
              id: "stp1189-acg4492-acd8155-acf1675-acn0932-acd8223-acg4790-acg4789-acd7896-acd7900-acd7909-acg6021",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Ventricular Assist Device",
          slug: "ventricular_assist_device",
          image: ManRunning,
          imageAlt: "man running on road near grass field",
          subphases: [
            {
              id: "zm2415-abs9877-acd8450",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A ventricular assist device (VAD), also known as a heart pump, is a mechanical device that helps pump blood from the heart to the rest of your body.",
              ],
            },
            {
              id: "abs9875-acd4959-acf8395",
              title: "After Surgery",
              slug: "after",
              summary:
                "Your doctor will give you detailed instructions about how to care for your device. These will include how to care for the exit site, where the cable comes out of your belly. A team of specialists will help you with your device and your treatment plan. You'll have…",
            },
            {
              id: "abs9874-acd4957-abs9876-acd4958",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
        {
          id: "",
          title: "Wearable Cardiac Defibrillator",
          slug: "wearable_cardiac_defibrillator",
          image: ManRunning,
          imageAlt: "man running on road near grass field",
          subphases: [
            {
              id: "acf2679-acf2692",
              title: "Before Surgery",
              slug: "before",
              paragraphs: [
                "A wearable cardioverter-defibrillator (WCD) is a vest that has a defibrillator built into it. A defibrillator is a device that fixes serious changes in your heartbeat. The device is always checking your heart rate and rhythm. If it detects a life-threatening, rapid heart rhythm, it sends an electric shock to the heart. This can restore a normal rhythm. A WCD helps control abnormal heart rhythms.",
              ],
            },
            {
              id: "abx0710-abp4622-ack9904-abx0707",
              title: "After Surgery",
              slug: "after",
              summaryParagraphs: [
                "Monitoring your medicines helps you and your doctors to:",
                "•	Be sure you are getting the right dose for your age, sex, and weight.",
                "•	Be sure the medicine is working for you…",
              ],
            },
            {
              id: "acf2696-acg5151-acg5152-acf2680",
              title: "Frequently Asked Questions",
              slug: "faq",
              summary:
                "Do you need quick answers to common questions? Select here! The Frequently Asked Questions section is a great place to refresh your memory or learn new facts and figures about your procedure or surgery.",
            },
          ],
        },
      ],
    },
  },

  general: {
    slug: "general",
    banner: {
      image: GeneralHealthBanner,
      title: "General Health",
      reverseTitle: false,
    },
    procedures: {
      subtitle: "General Health Information",
      image: DoctorPhone,
      imageAlt:
        "person wearing lavatory gown with green stethoscope on neck using phone while standing",
      paragraphs: [
        "It’s normal to have a mix of feelings about surgery. We want you to feel ready and know what to expect. Explore the topics below to learn more and feel more prepared! These are things your doctor might have already told you, or you’ve read about. Our goal is to give you the knowledge you need for a successful surgery and a smooth recovery.",
      ],
      phases: [
        {
          id: "",
          title: "Anesthesia",
          slug: "anesthesia",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "Anesthesia is the use of medicines to control pain and keep a person comfortable. Anesthetics are used to numb a specific area of the body (local and regional anesthesia). Or they can cause a person to be unconscious and not have pain during a procedure such as surgery (general anesthesia). Anesthesiologists are doctors who give anesthesia and watch over patients during surgery. Anesthesia may be given by a shot, mask, or breathing tube.",
          ],
          subphases: [
            {
              title: "Anesthesia",
              slug: "anesthesia",
            },
            {
              title: "General Anesthesia",
              slug: "general_anesthesia",
            },
            {
              title: "IV Regional Anesthesia",
              slug: "iv_regional_anesthesia",
            },
            {
              title: "Peripheral Nerve Block for Anesthesia",
              slug: "peripheral_nerve_block_for_anesthesia",
            },
            {
              title: "Regional Anesthesia",
              slug: "regional_anesthesia",
            },
            {
              title: "Spinal and Epidural Anesthesia",
              slug: "spinal_and_epidural_anesthesia",
            },
          ],
        },
        {
          id: "",
          title: "Medication",
          slug: "medication",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "Medications are tools doctors use to help people feel better. The most important thing about medicine is taking it exactly how your doctor tells you to. They know the best medicine for you and how much to take. They can also answer any questions you have about what it does and how it might make you feel.",
            "Here are some good questions to ask your doctor about your medicine:",
            "•	What is the name of this medicine?",
            "•	Why am I taking it?",
            "•	How long will I need to take it?",
            "•	How much do I take?",
            "•	Are there any side effects I should know about?",
          ],
          subphases: [
            {
              title: "Acetaminophen",
              slug: "acetaminophen",
            },
            {
              title: "Muscle Relaxants",
              slug: "muscle_relaxants",
            },
            {
              title: "NSAIDs",
              slug: "nsaids",
            },
            {
              title: "Opioids",
              slug: "opioids",
            },
          ],
        },
        {
          id: "",
          title: "Exercises",
          slug: "exercises",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "Find exercises to help you with strength and healing. Experts say that being active before and after surgery will help you heal faster.",
          ],
          subphases: [
            {
              title: "ACL Injury Excerises",
              slug: "acl_injury_exercises",
            },
            {
              title: "Ankle Pumps",
              slug: "ankle_pumps",
            },
            {
              title: "Fall Prevention Exercises",
              slug: "fall_prevention_exercises",
            },
            {
              title: "Heel Slide",
              slug: "heel_slide",
            },
            {
              title: "Passive Knee Flexor Stretch",
              slug: "passive_knee_flexor_stretch",
            },
            {
              title: "Quad Set",
              slug: "quad_set",
            },
            {
              title: "Seated Knee Flexion",
              slug: "seated_knee_flexion",
            },
            {
              title: "Short-Arc (Terminal) Knee Extension (Standing)",
              slug: "short_arc_terminal_knee_extension_standing",
            },
            {
              title: "Stationary Biking",
              slug: "stationary_biking",
            },
            {
              title: "Straight-Leg Raise Exercise",
              slug: "straight_leg_raise_Exercise",
            },
            {
              title: "Water Exercise",
              slug: "water_exercise",
            },
          ],
        },
        {
          id: "",
          title: "Healthcare and Hospital Terms",
          slug: "healthcare_and_hospital_terms",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "Have you come across a word or phrase in healthcare you didn’t know? These are terms you may have heard when talking with your hospital/health plan. There are many complex terms in healthcare that will have your head spinning. Look up these terms to learn more about them.",
          ],
          subphases: [
            {
              title: "Doctor-Patient Relationship",
              slug: "doctor_patient_relationship",
            },
            {
              title: "HIPAA",
              slug: "hipaa",
            },
            {
              title: "Informed Consent",
              slug: "informed_consent",
            },
            {
              title: "Patient Advocacy",
              slug: "patient_advocacy",
            },
            {
              title: "Patient Rights",
              slug: "patient_rights",
            },
            {
              title: "Discharge from the Hospital",
              slug: "discharge_from_the_hospital",
            },
            {
              title: "Follow-Up Appointments",
              slug: "follow_up_appointments",
            },
            {
              title: "Hospital Stay",
              slug: "hospital_stay",
            },
            {
              title: "Sleep in the Hospital",
              slug: "sleep_in_the_hospital",
            },
          ],
        },
        {
          id: "",
          title: "Healthy Habits",
          slug: "healthy_habits",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "No matter where you are in your journey, it is always good to make sure you have healthy habits, like a healthy diet, plenty of exercise, and good sleep. Learn more about how these habits can make you feel better each day, and help you recover from surgery.",
          ],
          subphases: [
            {
              title: "Healthy Eating",
              slug: "healthy_eating",
            },
            {
              title: "Breating Relaxation Techniques",
              slug: "breathing_relaxation_techniques",
            },
            {
              title: "Good Posture",
              slug: "good_posture",
            },
            {
              title: "Hand-Washing",
              slug: "hand_washing",
            },
            {
              title: "Meditation",
              slug: "meditation",
            },
            {
              title: "Mindfulness",
              slug: "mindfulness",
            },
            {
              title: "Stress Management",
              slug: "stress_management",
            },
          ],
        },
        {
          id: "",
          title: "Medical Terms",
          slug: "medical_terms",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "Did your doctor ever give you medical information with words or phrases you didn’t know? You’re not alone. Here, we break down common medical terms into simpler meanings.",
          ],
          subphases: [
            {
              title: "Blood Clots",
              slug: "blood_clots",
            },
            {
              title: "Blood Thinners",
              slug: "blood_thinners",
            },
            {
              title: "Catheter-Associated Urinary Tract Infection",
              slug: "catheter_associated_urinary_tract_infection",
            },
            {
              title: "Chlorhexidine Soap",
              slug: "chlorhexidine_soap",
            },
            {
              title: "Deep Vein Thrombosis (DVT)",
              slug: "deep_vein_thrombosis_dvt",
            },
            {
              title: "Failed Back Surgery Syndrome",
              slug: "failed_back_surgery_syndrome",
            },
            {
              title: "Hip Dislocation",
              slug: "hip_dislocation",
            },
            {
              title: "Incentive Spirometer",
              slug: "incentive_spirometer",
            },
            {
              title: "Inpatient Ambulation",
              slug: "inpatient_ambulation",
            },
            {
              title: "Knee Arthritis",
              slug: "knee_arthritis",
            },
            {
              title: "Laminectomy",
              slug: "laminectomy",
            },
            {
              title: "Orthopedic Hardware Removal",
              slug: "orthopedic_hardware_removal",
            },
            {
              title: "Pressure Injuries",
              slug: "pressure_injuries",
            },
            {
              title: "Sepsis",
              slug: "sepsis",
            },
            {
              title: "Spinal Stenosis",
              slug: "spinal_stenosis",
            },
          ],
        },
        {
          id: "",
          title: "Pain",
          slug: "pain",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "No one likes pain but it plays a key role in your body. Pain is your body's way of warning you that something may be wrong. Pain can be scary and affect your feelings and body. But it does not have to control you. Learn what pain is and how to manage it here.",
          ],
          subphases: [
            {
              title: "What are the different types of pain?",
              slug: "what_are_the_different_types_of_pain",
            },
            {
              title: "Pain",
              slug: "pain",
            },
            {
              title: "Pain Assessment",
              slug: "pain_assessment",
            },
            {
              title: "Pain Management",
              slug: "pain_management",
            },
            {
              title: "Pain Management After Leaving the Hospital",
              slug: "pain_management_after_leaving_the_hospital",
            },
            {
              title: "Pain Management in the Hospital",
              slug: "pain_management_in_the_hospital",
            },
            {
              title: "Patient-Controlled Analgesia (PCA) Pump",
              slug: "patient_controlled_analgesia_pca_pump",
            },
            {
              title: "Postoperative Pain",
              slug: "postoperative_pain",
            },
          ],
        },
        {
          id: "",
          title: "Safety",
          slug: "safety",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "Learn how to keep yourself safe at home or outside. This section talks about using things called assistive devices, which can help us move around more safely as well as other topics to keep you safe in the hospital. It's key to pay attention to our surroundings and to listen to others when they offer help. Knowing these safety tips will help us stay safe no matter where we are.",
          ],
          subphases: [
            {
              title: "Anticoagulant Safety in the Hospital",
              slug: "anticoagulant_safety_in_the_hospital",
            },
            {
              title: "Assistive Devices",
              slug: "assistive_devices",
            },
            {
              title: "Assistive Devices for Bathing or Grooming",
              slug: "assistive_devices_for_bathing_or_grooming",
            },
            {
              title: "Canes",
              slug: "canes",
            },
            {
              title: "Car Transfer Safety",
              slug: "car_transfer_safety",
            },
            {
              title: "Crutches",
              slug: "crutches",
            },
            {
              title: "Fall Prevention in Adults",
              slug: "fall_prevention_in_adults",
            },
            {
              title: "Getting Up Safely After Falling",
              slug: "getting_up_safely_after_falling",
            },
            {
              title: "Home Health Care",
              slug: "home_health_care",
            },
            {
              title: "Hospital and Patient Safety",
              slug: "hospital_and_patient_safety",
            },
            {
              title: "Medical Errors",
              slug: "medical_errors",
            },
            {
              title: "Medication Monitoring",
              slug: "medication_monitoring",
            },
            {
              title: "Medication Storage and Disposal",
              slug: "medication_storage_and_disposal",
            },
            {
              title: "Medication Use and Safety",
              slug: "medication_use_and_safety",
            },
            {
              title: "Orthotic Devices",
              slug: "orthotic_devices",
            },
            {
              title: "Preventing Falls in the Hospital",
              slug: "preventing_falls_in_the_hospital",
            },
            {
              title: "Preventing Hospital Infections",
              slug: "preventing_hospital_infections",
            },
            {
              title: "Staying Safe in the Hospital",
              slug: "staying_safe_in_the_hospital",
            },
            {
              title: "Walker",
              slug: "walker",
            },
          ],
        },
        {
          id: "",
          title: "Surgery Facts",
          slug: "surgery_facts",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "There are things you can do before surgery to help you feel your best. Take a look at this helpful content to prepare for your future procedure.",
          ],
          subphases: [
            {
              title: "Bleeding After Surgery",
              slug: "bleeding_after_surgery",
            },
            {
              title: "Enhanced Recovery After Surgery",
              slug: "enhanced_recovery_after_surgery",
            },
            {
              title: "Nausea and Vomiting After Surgery",
              slug: "nausea_and_vomiting_after_surgery",
            },
            {
              title: "Postoperative Care",
              slug: "postoperative_care",
            },
            {
              title: "Postoperative Problems",
              slug: "postoperative_problems",
            },
            {
              title: "Preoperative Care",
              slug: "preoperative_care",
            },
            {
              title: "Preoperative Care for Diabetic Patients",
              slug: "preoperative_care_for_diabetic_patients",
            },
            {
              title: "Surgery",
              slug: "surgery",
            },
            {
              title: "Surgical Drain",
              slug: "surgical_drain",
            },
          ],
        },
        {
          id: "",
          title: "Treatments & Therapy",
          slug: "treatments_and_therapy",
          image: Books,
          imageAlt: "assorted books on wooden table",
          paragraphs: [
            "Getting better needs patience and work. You don't have to rush, just go little by little every day to start feeling better. Here, you'll find out about the treatments and therapies to help you get better, faster.",
          ],
          subphases: [
            {
              title: "Cold Therapy",
              slug: "cold_therapy",
            },
            {
              title: "Compression Stockings",
              slug: "compression_stockings",
            },
            {
              title: "Physical Therapy",
              slug: "physical_therapy",
            },
          ],
        },
      ],
    },
  },
};
