import styled from "@emotion/styled";
import { Box } from "@mui/material";
import Banner from "../../../assets/img/banner.jpeg";

export const StyledBanner = styled(Box)(
  ({ theme, imageSrc, reverseTitle, height }) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: -1,
    height: height,
    borderBottomLeftRadius: "50% 25%",
    borderBottomRightRadius: "50% 25%",
    transform: "translate(0, -56px)",
    background: `linear-gradient(${
      reverseTitle ? 270 : 90
    }deg, #FFF 15%, rgba(255, 255, 255, 0) 65%), url(${
      imageSrc || Banner
    })`, // lightgray 0 -100px / 100% 194% no-repeat
    backgroundSize: "cover",
    backgroundPosition: `0px ${reverseTitle ? 65 : 30}%`,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      background: `radial-gradient(circle, #FFF 3%, rgba(255, 255, 255, 0) 75%), url(${
        imageSrc || Banner
      })`,
    },

    [theme.breakpoints.down("lg")]: {
      transform: "translate(0, -36px)",
      backgroundOrigin: "content-box",
      height: 280,
      backgroundSize: "cover",
      backgroundPosition: `0px ${reverseTitle ? 65 : 30}%`,
      borderBottomLeftRadius: "50% 7%",
      borderBottomRightRadius: "50% 7%",
    },
  })
);
