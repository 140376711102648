import { useEffect, useState } from "react";
import { getWithExpiry, setWithExpiry } from "../utils/storage";
import { sessionLength } from "../data/constants";
import log from "../api/log";

const useToken = () => {
  const [token, setToken] = useState(getWithExpiry("token"));

  useEffect(() => {
    const tokenWithExpiry = getWithExpiry("token");
    setToken(tokenWithExpiry);
  }, []);

  return {
    token,
    setToken: (token) => {
      setWithExpiry("token", token, sessionLength);
      setToken(token);

      log.session("information", { message: "Session created", success: true })
    },
  };
};

export default useToken;
