import { createBrowserRouter } from "react-router-dom";
import ProcedurePage from "./pages/ProcedurePage";
import ArticlePage from "./pages/ArticlePage";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import Layout from "./pages/components/Layout/Layout";
import TOSPage from "./pages/TOSPage";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/privacyPolicy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/termsOfService",
        element: <TOSPage />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    element: <Layout procedurePage footer backToTop />,
    children: [
      {
        path: "/procedures/:procedure",
        element: <ProcedurePage />,
      },
    ],
  },
  {
    element: <Layout footer backToTop />,
    children: [
      {
        path: "/topics/:procedureSlug/:slug",
        element: <ArticlePage />,
      },
    ],
  },
]);
