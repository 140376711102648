import { Button, Stack } from "@mui/material";
import { RightArrowIcon } from "../../assets/icons/RightArrowIcon";
import TypographyT5 from "../Basic/Typography/TypographyT5";

const ReadArticle = ({
  id,
  title = "Read Full Article",
  hideTitle,
  onClick,
}) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent={[hideTitle ? "end" : "start", "end"]}
    >
      <Button
        endIcon={<RightArrowIcon />}
        sx={{
          justifyContent: "end",
          "&:focus": { boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.5)" },
          "&:hover": { boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.5)" },
        }}
        onClick={onClick}
      >
        <TypographyT5 color="text.secondary" fontSize="20px" p={0}>
          {title}
        </TypographyT5>
      </Button>
    </Stack>
  );
};

export default ReadArticle;
