import { SvgIcon } from "@mui/material";

export const GeneralHealthIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="26"
        viewBox="0 0 27 26"
        fill="none"
      >
        <path
          d="M9.25 1V0H8.25V1H9.25ZM17.25 1H18.25V0H17.25V1ZM9.25 9V10L10.25 10V9H9.25ZM1.25 9L1.25 8H0.25V9H1.25ZM1.25 17H0.25V18H1.25V17ZM9.25 17H10.25V16H9.25V17ZM9.25 25H8.25V26H9.25V25ZM17.25 25V26H18.25V25H17.25ZM17.25 17V16H16.25V17H17.25ZM25.25 17V18H26.25V17H25.25ZM25.25 9H26.25V8H25.25V9ZM17.25 9H16.25V10H17.25V9ZM9.25 2H17.25V0H9.25V2ZM10.25 9V1H8.25V9H10.25ZM1.25 10H9.25V8L1.25 8L1.25 10ZM2.25 17V9H0.25V17H2.25ZM9.25 16H1.25V18H9.25V16ZM10.25 25V17H8.25V25H10.25ZM17.25 24H9.25V26H17.25V24ZM16.25 17V25H18.25V17H16.25ZM25.25 16H17.25V18H25.25V16ZM24.25 9V17H26.25V9H24.25ZM17.25 10L25.25 10V8L17.25 8V10ZM16.25 1V9H18.25V1H16.25Z"
          fill="#C4C4C4"
        />
      </svg>
    </SvgIcon>
  );
};
