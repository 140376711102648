import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const SelectButton = styled(Button)(({ theme }) => ({
  margin: "0 20px 24px",
  borderRadius: 100,
  ":hover": {
    background: theme.palette.primary.main,
    color: "#FFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
  },
  padding: "8px 40px",
  ":focus": {
    background: theme.palette.primary.main,
    color: "#FFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
  },
}));

export const FeedbackRate = styled(Button)(({ theme, isSelected }) => ({
  width: 44,
  height: 44,
  fontSize: "1rem",
  lineHeight: 1.56,
  minWidth: 0,
  borderRadius: 100,
  margin: "0 9px",
  cursor: "pointer",
  background: isSelected ? theme.palette.primary.main : "#FFF",
  color: isSelected ? "#FFF" : "initial",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  "&:hover": {
    background: theme.palette.primary.main,
    color: "#FFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
  },
  "&:focus": {
    background: theme.palette.primary.main,
    color: "#FFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
  },
}));
