import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import useTrackPageView from "../../../hooks/useTrackPageView";

import ArticleOverview from "../../components/ArticleOverview/ArticleOverview";
import ArticleBanner from "../../components/ArticleBanner/ArticleBanner";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import ArticleContent from "../../components/ArticleContent/ArticleContent";
import SectionBox from "../../../components/SectionBox/SectionBox";
import { CategorySection } from "../../components/EducationCategory/EducationCategory.styles";

import { procedurePages } from "../../../data/procedures";
import { contentAPI } from "../../../api/endpoints";
import api from "../../../api";
import NotFound from "../../NotFound";
import { extendExpiry } from "../../../utils/storage";
import { sessionLength } from "../../../data/constants";
import TypographyT3 from "../../../components/Basic/Typography/TypographyT3";
import getInjectedVideoHtml from "../../../utils/video";
import MemberPopUp from "../../../components/MemberPopUp/MemberPopUp";
import FeedbackModal from "../../../components/FeedbackModal/FeedbackModal";
import log from "../../../api/log";

const Article = ({ procedureSlug, slug }) => {
  const navigate = useNavigate();

  const trackPageView = useTrackPageView();

  const [topic, setTopic] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const getCurrentSubphase = (phase) =>
    phase.subphases.find((sub) => sub.slug === slug);

  const fetchTopics = async () => {
    try {
      const response = await Promise.resolve(
        api(contentAPI(procedureSlug, slug))
      );

      if (response.status === 200) {
        const topicData = response.data.hwContent
          .map((resp) => {
            const topic = resp.data?.topics?.[0];
            if (topic.type === "hwVideo") return getInjectedVideoHtml(topic, procedureSlug, slug);
            return topic.html;
          })
          .join("\n");

        setTopic(topicData);
        !topicData && setNotFound(true);
      }
    } catch (error) {
      // do we need additional error handling here for 500 responses?
      if (error.response?.status === 404) {
        setNotFound(true);
      }

      log.error("Topics couldn't be fetched", "Frontend", error)
    }
  };

  const currentPhase = useMemo(
    () =>
      Object.entries(procedurePages).reduce(
        (acc, [pName, procedure]) =>
          procedure.procedures.phases.find(
            (phase) => phase.slug === procedureSlug && getCurrentSubphase(phase)
          ) || acc,
        {}
      ),
    [slug]
  );
  let title;
  let bannerTitle;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [topic]);

  useEffect(() => {
    extendExpiry("token", sessionLength);
    fetchTopics();
  }, [slug]);

  useEffect(() => {
    if (title && bannerTitle) {
      trackPageView("Procedure Page Visit", {
        slug,
        title,
        category: bannerTitle,
      });
    }
  }, [title]);

  if (!currentPhase.hasOwnProperty("subphases")) {
    log.pageView("error", {
      message: "Article Page View Failed",
      procedure: procedureSlug,
      topic: slug,
      success: false,
      url: window.location.href,
    });
    return <NotFound />;
  }

  const currentSubPhase = getCurrentSubphase(currentPhase);

  const getRestSubphases = () =>
    currentPhase.subphases.filter((sub) => sub.slug !== slug);

  title = currentSubPhase.title;
  bannerTitle = currentPhase.title || title;

  const procedureReducer = (acc, [pName, procedure]) => {
    const result = procedure.procedures.phases.find(
      (phase) => phase.slug === procedureSlug && getCurrentSubphase(phase)
    );
    if (result) return procedurePages[pName].slug;
    return acc;
  };

  const onBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      const procedurePage = Object.entries(procedurePages).reduce(
        procedureReducer,
        {}
      );

      const url = `/procedures/${procedurePage}`;
      navigate(url, { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  return (
    <>
      <ArticleBanner title={bannerTitle} />
      <Breadcrumbs isBack onClickBreadcrumb={onBack} />
      <Box sx={{ "> *": { mb: [2, 6] } }}>
        {topic && <ArticleContent title={title} content={topic} />}
        {notFound && (
          <SectionBox>
            <TypographyT3> Topic not found </TypographyT3>
          </SectionBox>
        )}

        <CategorySection container darkBg px={[3, 16]} py={[2, 4]}>
          <ArticleOverview
            slug={slug}
            scrollTop
            isArticlePage
            subphases={getRestSubphases()}
            title="Procedure Steps"
            procedureSlug={procedureSlug}
          />
        </CategorySection>
      </Box>
      <MemberPopUp />
      <FeedbackModal procedure={procedureSlug} topic={slug} />
    </>
  );
};

export default Article;
