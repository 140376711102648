import { ArrowUpward } from "@mui/icons-material";
import BlueButton from "../Basic/BlueButton/BlueButton";

const BackToTopButton = () => {
  return (
    <BlueButton
      endIcon={<ArrowUpward />}
      style={{
        width: "auto",
        position: "fixed",
        bottom: 15,
        right: 15,
        display: "flex",
      }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      Back To Top
    </BlueButton>
  );
};

export default BackToTopButton;
