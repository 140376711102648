import styled from "@emotion/styled";

export const NavBar = styled("nav")(({ theme, procedurePage = false }) => ({
  display: "flex",
  padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(
    2
  )} ${theme.spacing(8)} `,
  alignItems: "center",
  justifyContent: "space-between",
  gap: 60,
  background: "#FFF",
  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
  img: { cursor: "pointer" },
  position: "fixed",
  width: "100%",
  top: 0,
  left: 0,
  zIndex: 99,

  [theme.breakpoints.down("sm")]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    display : procedurePage ? "block" : "flex",
    textAlign: "center"
  },
}));
