import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ReadArticle from "../ReadArticle/ReadArticle";
import { StyledArticleBox } from "./ArticleBox.styles";
import TypographyT4 from "../Basic/Typography/TypographyT4";

const ArticleBox = ({ item, size = 2, scrollTop = false, procedureSlug }) => {
  const navigate = useNavigate();
  const { slug, title, summary, paragraphs, summaryParagraphs } = item;

  const handleRead = (evt) => {
    if (slug) {
      navigate(`/topics/${procedureSlug}/${slug}`, {
        preventScrollReset: false,
      });
      scrollTop && window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={12 / size}
      display="flex"
      justifyContent="stretch"
    >
      <StyledArticleBox minHeight={[170, 200]}>
        <Box sx={{ justifyContent: "start", width: "fit-content", p: 0 }}>
          <TypographyT4 textAlign="start">{title}</TypographyT4>
        </Box>

        <Typography variant="body1">
          {summary ||
            paragraphs?.[0] ||
            summaryParagraphs.map((text) => (
              <Typography key={text} variant="body1" paragraph>
                {text}
              </Typography>
            ))}
        </Typography>
        <ReadArticle hideTitle onClick={handleRead} />
      </StyledArticleBox>
    </Grid>
  );
};

export default ArticleBox;
