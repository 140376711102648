import { Box, Typography } from "@mui/material";
import Line from "../components/Line/Line";
import { useEffect } from "react";
import log from "../api/log";

const TOSPage = () => {
  useEffect(() => {
    log.pageView("information", {
      message: "TOS Page View",
      success: true,
      url: window.location.href
    });
  }, []);
  return (
    <>
      <Typography variant="h1" marginTop="4px" textAlign={"center"}>
        Terms of Service
      </Typography>
      <Line />
      <Box
        marginTop="1em"
        paddingX="5%"
        display={"flex"}
        flexDirection={"column"}
        flexWrap={"wrap"}
      >
        <Typography fontStyle="italic" variant="body1margin">
          Last updated: June 12, 2024
        </Typography>
        <Typography variant="body1margin">
          <b>
            THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR
            RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND
            EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY.
          </b>
        </Typography>
        <Typography variant="body1margin">
          These Terms of Service (“<b>Terms</b>”) apply to the use of the
          website{" "}
          <a
            href="https://myhealth.tpshealth.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            myhealth.tpshealth.com
          </a>{" "}
          or other services that link to these Terms (collectively, the “
          <b>Services</b>”). The Services are made available by TurningPoint
          Healthcare Solutions LLC and its affiliated professional entities (“
          <b>TurningPoint Professional Entities</b>”) and their respective
          affiliates (collectively, “<b>TurningPoint</b>”, “<b>we</b>”, “
          <b>our</b>” or “<b>us</b>”).
        </Typography>
        <Typography variant="body1margin">
          By accessing, browsing, and/or using the Services, you acknowledge
          that you have read and agree to be bound by these Terms.
        </Typography>
        <Typography variant="body1margin">
          <b>
            THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO
            RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
          </b>
        </Typography>
        <Typography variant="body1margin">
          <b>
            YOU MAY NOT USE THE SERVICES IF YOU:{" "}
            <ul>
              <li>DO NOT AGREE TO THESE TERMS,</li>
              <li>ARE NOT AT LEAST 18 YEARS OF AGE, OR</li>
              <li>
                ARE PROHIBITED FROM ACCESSING OR USING THE SERVICES OR ANY
                CONTENTS OF SERVICES BY APPLICABLE LAW.
              </li>
            </ul>
          </b>
        </Typography>
        <Typography variant="body1margin">
          <b>1. Changes to These Terms.</b> These Terms are subject to change by
          us at any time and without prior notice, in our sole discretion. Any
          changes to these Terms will be in effect as of the “Last Updated” date
          referenced above. If we make material changes to these Terms, we will
          make reasonable efforts to notify you of such changes (such as posting
          a notice on a Service or, if you have provided us with your email,
          sending you an email notification) and, if you have registered an
          account with a Service, we may ask you to affirmatively consent to the
          changes at the time of your next account login. Any use of the
          Services after such date shall constitute your acceptance of such
          revised terms and conditions. If any change to these Terms is not
          acceptable to you, your sole remedy is to cease accessing, browsing
          and otherwise using the Services.
        </Typography>
        <Typography variant="body1margin">
          <b>2. Privacy.</b> Please read our Privacy Policy, available at
          myhealth.tpshealth.com, for information on our data collection, use
          and sharing policies and practices in connection with the Services.
          Please note that we provide services to health plans and may interact
          with you as a member of a health plan. In the course of such services,
          we may collect your protected health information governed by the
          Health Insurance Portability and Accountability Act of 1996 (“
          <b>HIPAA”</b>). Where that is the case, your health care provider's
          HIPAA Notice of Privacy Practices will govern our collection and use
          of such protected health information, not the Privacy Policy.
        </Typography>
        <Typography variant="body1margin">
          <b>3. LIMITATIONS REGARDING LICENSED SERVICES.</b>
        </Typography>
        <Typography variant="body1margin">
          TURNINGPOINT OR THE TURNINGPOINT PROFESSIONAL ENTITIES PROVIDE A
          VARIETY OF DIFFERENT HEALTH AND WELLNESS SERVICES. TURNINGPOINT
          HEALTHCARE SOLUTIONS LLC IS NOT A HEALTH CARE PROVIDER BUT MAY SERVE
          AS A MANAGEMENT COMPANY TO THE TURNINGPOINT PROFESSIONAL ENTITIES.
          NOTWITHSTANDING THE FOREGOING, NO SERVICE PROVIDED THROUGH THIS
          WEBSITE CONSTITUTES A PROFESSIONAL SERVICE FOR WHICH A PROFESSIONAL
          LICENSE IS REQUIRED. NEITHER TURNINGPOINT OR THE TURNINGPOINT
          PROFESSIONAL ENTITIES PROVIDE ANY MEDICAL ADVICE OR MEDICAL TREATMENT
          IN CONNECTION WITH THIS WEBSITE. ALL OF THE CONTENT AVAILABLE ON OR
          THROUGH THE WEBSITE IS INTENDED SOLELY AS A GENERAL EDUCATIONAL AID
          FOR INFORMATIONAL PURPOSES ONLY. IT IS NOT MEDICAL OR HEALTH CARE
          ADVICE. IT IS NOT TO BE USED FOR MEDICAL DIAGNOSIS OR FOR TREATMENT.
          IT IS NOT A SUBSTITUTE FOR PROFESSIONAL ADVICE AND SERVICES FROM A
          PHYSICIAN (OR OTHER QUALIFIED HEALTH CARE PROVIDER) WHO IS FAMILIAR
          WITH YOUR UNIQUE FACTS. ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN (OR
          OTHER QUALIFIED HEALTH CARE PROVIDER) REGARDING ANY MEDICAL CONDITION
          AND BEFORE CHANGING A TREATMENT OR STARTING ANY NEW TREATMENT.
        </Typography>
        <Typography variant="body1margin">
          ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN OR OTHER QUALIFIED HEALTH
          PROVIDERS BEFORE DECIDING TO START, ALTER OR DISCONTINUE ANY COURSE OF
          MEDICAL TREATMENT OR FOR ANY QUESTIONS REGARDING YOUR MEDICAL
          CONDITION, TREATMENT OR THE USE (OR FREQUENCY) OF ANY MEDICATION OR
          MEDICAL DEVICE. DO NOT USE THE SERVICES AS A SUBSTITUTE FOR CONSULTING
          WITH YOUR PHYSICIAN OR OTHER HEALTH CARE PROVIDER, AND DO NOT
          DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE
          OF ANY INFORMATION RECEIVED IN CONNECTION WITH OUR SERVICES. YOUR
          RELIANCE ON ANY INFORMATION OR OTHER CONTENT AVAILABLE ON OR THROUGH
          THE SERVICES OR OTHERWISE PROVIDED BY TURNINGPOINT IS SOLELY AT YOUR
          OWN RISK AND WE ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING
          DIRECTLY OR INDIRECTLY TO ANY ACTION OR INACTION YOU TAKE BASED ON THE
          CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE SERVICES.
        </Typography>
        <Typography variant="body1margin">
          THE SERVICES ARE NOT INTENDED FOR USE IN AN EMERGENCY. IF YOU THINK
          YOU MAY HAVE A MEDICAL EMERGENCY, CALL YOUR DOCTOR OR 911 IMMEDIATELY.
          NEVER DELAY IN SEEKING PROFESSIONAL MEDICAL ADVICE OR DISREGARD SUCH
          ADVICE BECAUSE OF SOMETHING YOU HAVE READ ON THE SERVICES.
        </Typography>
        <Typography variant="body1margin">
          <b>4. Account Registration and Security.</b> Before you are able to
          use the Services, you may be required to enter a unique Access Code.
          You agree to provide complete, accurate information during the
          registration process and to update such information as necessary to
          ensure that it remains complete, accurate and up-to-date. You further
          acknowledge and agree that you will be solely responsible for any
          activities or actions on or through your account, whether or not you
          have authorized such activities or actions. We will not be liable for
          any loss or damage arising from your failure to comply with these
          requirements. We reserve the right to disable any identifier, whether
          chosen by you or provided by us, at any time in our sole discretion
          for any or no reason, including if, in our opinion, you have violated
          any provision of these Terms.
        </Typography>
        <Typography variant="body1margin">
          <b>5. Intellectual Property.</b>The Services (and their entire
          contents, features, and functionality, including but not limited to
          all information, software, text, displays, images, video, and audio,
          and the design, selection, and arrangement thereof (collectively, “
          <b>Content</b>”)) are owned by the Company, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws. No license, right,
          title or interest in the Services or Content is transferred to you as
          a result of your downloading, accessing, viewing or using the Services
          or Content.
        </Typography>
        <Typography variant="body1margin">
          These Terms permit you to access, view and use the Services on any
          device that you own or control for your personal, non-commercial use
          only. You must not reproduce, distribute, modify, create derivative
          works of, publicly display, publicly perform, republish, download,
          store or transmit any of the material available through the Services
          or any Content, except as permitted by these Terms. You may not
          reproduce, publish, transmit, distribute, display, modify, create
          derivative works from, sell or participate in any sale of or exploit
          in any way, in whole or in part, any of the Services or any Content.
          Any other use, including the reproduction, modification, distribution,
          transmission, republication, display or performance of the Services or
          the Content is strictly prohibited.
        </Typography>
        <Typography variant="body1margin">
          <b>6. Trademarks.</b> The TurningPoint name and all other trademarks,
          wordmarks, service marks, graphics and logos included in or made
          available through the Services are trademarks or trade dress of
          TurningPoint. All other marks are the property of their respective
          owners.
        </Typography>
        <Typography variant="body1margin">
          <b>7. Modifications to the Services.</b> We reserve the right to
          modify or withdraw the Services, and any Content we provide through
          the Services, in our sole discretion without notice. We will not be
          liable if for any reason all or any part of the Services is
          unavailable at any time or for any period. From time to time, we may
          restrict access to some parts of the Services, or an entire Service,
          to users, including registered users.
        </Typography>
        <Typography variant="body1margin">
          <b>8. User Content and Feedback.</b> If you make available to us any
          content in any format (including ideas, concepts, feedback, and
          know-how (“<b>Feedback</b>”)), you hereby grant to TurningPoint a
          worldwide, royalty-free, fully paid-up, non-exclusive, perpetual,
          irrevocable, transferable and fully sublicensable license to
          reproduce, distribute, perform and display (publicly or otherwise),
          create derivative works of, adapt, modify and otherwise use, analyze
          and exploit such content, in any format or media now known or
          hereafter developed, and for any purpose. You acknowledge and agree
          that Feedback is not confidential, and that we are free to use any
          Feedback for any purpose.
        </Typography>
        <Typography variant="body1margin">
          <b>9. Prohibited Uses.</b> You may use the Services only for lawful
          purposes and in accordance with these Terms.
        </Typography>
        <Typography variant="body1margin">
          You agree not to use the Services:
          <ul>
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li>
              For the purpose of exploiting, harming or attempting to exploit or
              harm minors in any way by exposing them to inappropriate content,
              asking for personally identifiable information or otherwise.
            </li>
            <li>
              To send, knowingly receive, upload, download, use, or re-use any
              material that is offensive, harmful, infringing, obscene,
              defamatory, abusive, deceptive, untrue, misrepresentative or
              illegal.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material.
            </li>
            <li>
              To impersonate or attempt to impersonate TurningPoint, a
              TurningPoint employee, another user or any other person or entity.
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Services, or which, as determined by us,
              may harm TurningPoint or users of the Services or expose them to
              liability.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          Additionally, you agree not to:
          <ul>
            <li>
              Use the Services in any manner that could disable, overburden,
              damage or impair the Services or interfere with any other party's
              use of the Services.
            </li>
            <li>
              Use any robot, spider or other automatic device, process or means
              to access the Services for any purpose, including monitoring or
              copying any of the Content or other material on the Services.
            </li>
            <li>
              Use any manual process to monitor or copy any of the Content or
              material on the Services, or for any other purpose not expressly
              authorized in these Terms, without our prior written consent.
            </li>
            <li>
              Use any device, software or routine that interferes with the
              proper working of the Services.
            </li>
            <li>
              Introduce any viruses, Trojan horses, worms, logic bombs or other
              material that is malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage or
              disrupt any parts of the Services, the server on which the
              Services is stored, or any server, computer or database connected
              to the Services.
            </li>
            <li>
              Attack the Services via a denial-of-service attack or a
              distributed denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working of the
              Services.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          <b>10. Linked Sites; Third Party Materials.</b> The Services may
          provide access to websites, information, products, services and other
          materials made available by third parties (“
          <b>Third Party Materials</b>”). We are not responsible for any
          Third-Party Materials (including their accuracy, validity, timeliness,
          completeness, reliability, integrity, quality, legality, usefulness or
          safety, or any intellectual property rights contained in them). We do
          not have any obligation to monitor Third Party Materials, and we may
          block or disable access to any Third-Party Materials (in whole or
          part) through the Services at any time. The availability of any
          Third-Party Materials through the Services is not an endorsement of
          them by TurningPoint, and it does not imply any affiliation with any
          provider of Third-Party Materials. Your use of Third-Party Materials
          is at your own risk and is subject to any additional terms, conditions
          and policies applicable to the Third-Party Materials (like their terms
          of service or privacy policies).
        </Typography>
        <Typography variant="body1margin">
          <b>11. Termination.</b> TurningPoint, in its sole discretion, may
          terminate or suspend your access to or use of the Services without
          notice for any reason, including, without limitation, if we believe
          that you have violated or acted inconsistently with the letter or
          spirit of these Terms. Upon termination, all provisions of this
          Agreement which by their nature should survive termination shall
          survive termination, including, without limitation, intellectual
          property provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </Typography>
        <Typography variant="body1margin">
          <b>12. No Representations or Warranties.</b> YOUR USE OF THE SERVICES
          AND ANY CONTENT OR ITEMS OBTAINED THROUGH THE SERVICES IS AT YOUR OWN
          RISK. THE SERVICES, CONTENT AND ITEMS OBTAINED THROUGH THE SERVICES
          ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
          TURNINGPOINT NOR ANY PERSON ASSOCIATED WITH TURNINGPOINT MAKES ANY
          WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICES.
          WITHOUT LIMITING THE FOREGOING, NEITHER TURNINGPOINT NOR ANYONE
          ASSOCIATED WITH TURNINGPOINT REPRESENTS OR WARRANTS THAT THE SERVICES,
          CONTENT OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
          RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
          THAT OUR SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY
          CONTENT OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET
          YOUR NEEDS OR EXPECTATIONS. TO THE FULLEST EXTENT PROVIDED BY LAW, WE
          HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
          WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
          PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
          CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </Typography>
        <Typography variant="body1margin">
          <b>13. Limitation of Liability.</b> YOU ACKNOWLEDGE AND AGREE THAT, TO
          THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, TURNINGPOINT WILL
          NOT BE LIABLE TO YOU OR ANY OTHER PERSON, UNDER ANY CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY, FOR ANY
          DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR
          OTHER SPECIAL CATEGORY OF DAMAGES OF ANY KIND ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE OF (OR INABILITY TO USE) THE SERVICES, EVEN
          IF AN AUTHORIZED REPRESENTATIVE OF TURNINGPOINT HAS BEEN ADVISED OF OR
          SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST
          EXTENT PERMITTED BY LAW, THIS DISCLAIMER APPLIES TO ANY DAMAGES OR
          INJURY ARISING FROM ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
          INTERRUPTION, DELETION, DEFECTS, DELAYS, LOSS OF PROFITS, USE OF DATA,
          LOSS OF OTHER INTANGIBLES, WORK STOPPAGE, ACCURACY OF RESULTS, LOSS OF
          SECURITY OF MATERIALS (INCLUDING UNAUTHORIZED INTERCEPTION BY THIRD
          PARTIES OF ANY MATERIALS), COMPUTER FAILURE, VIRUS OR MALFUNCTION,
          FILE CORRUPTION OR OTHER OUTAGE OR ERROR. WITHOUT LIMITING THE
          FOREGOING DISCLAIMERS, TURNINGPOINT WILL NOT BE LIABLE FOR DAMAGES OF
          ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE SERVICES
          OR FROM ANY CONTENT, ITEMS OR THIRD-PARTY MATERIALS, INCLUDING FROM
          ANY DENIAL-OF-SERVICE ATTACK, VIRUS OR OTHER HARMFUL CODE OR MATERIAL
          THAT MAY AFFECT YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY BASED ON
          YOUR ACCESS TO, OR USE OF, THE SERVICES. TO THE FULLEST EXTENT
          PROVIDED BY APPLICABLE LAW, THE MAXIMUM AGGREGATE LIABILITY OF
          TURNINGPOINT FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN
          CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL NOT EXCEED
          THE GREATER OF (1) $100.00 OR (2) THE AMOUNT YOU PAID TO TURNINGPOINT
          IN THE ONE MONTH PERIOD IMMEDIATELY PRECEDING THE DATE ON WHICH THE
          CLAIM AROSE.
        </Typography>
        <Typography variant="body1margin">
          <b>14. Arbitration Agreement.</b>
        </Typography>
        <Typography variant="body1margin">
          <b>
            PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU
            TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH US AND LIMITS THE
            MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
          </b>{" "}
          This section is referred to herein as the “
          <b>Arbitration Agreement.</b>” The United States Federal Arbitration
          Act (9 U.S.C. §§ 1-16) governs the interpretation and enforcement of
          this Arbitration Agreement. You and TurningPoint agree that any and
          all controversies, claims and disputes arising out of or related to
          these Terms or the Services or any information provided through the
          Services, including Content, whether based in contract, tort,
          warranty, statute, or any other legal or equitable basis, including
          without limitation, any dispute or claim relating to the formation,
          interpretation or enforceability of any part of these Terms (including
          the scope and enforceability of this Arbitration Agreement) and any
          claim that all or any part of these Terms is void or voidable
          (collectively, “<b>Claims</b>” and individually, a “<b>Claim</b>”),
          shall be finally resolved by binding arbitration, rather than in
          court; except that you and we each retain the right: (i) to bring an
          individual action in small claims court (if the Claim in question
          qualifies for small claims court); and (ii) to seek injunctive or
          other equitable relief in court against actual or threatened
          infringement, misappropriation or violation of intellectual property
          rights. There is no judge or jury in arbitration, and court review of
          an arbitration award is limited. However, an arbitrator can award on
          an individual basis the same damages and relief as a court.
          <ul>
            <li>
              Arbitration will be conducted by a single and neutral arbitrator
              in accordance with the American Arbitration Association's (“
              <b>AAA</b>”){" "}
              <a
                href="https://www.adr.org/sites/default/files/Consumer_Rules_Web_0.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Consumer Arbitration Rules and procedures
              </a>{" "}
              (the “<b>AAA Rules</b>”), as modified by this Arbitration
              Agreement. If there is any inconsistency between the AAA Rules and
              this Arbitration Agreement, the terms of this Arbitration
              Agreement will control unless the arbitrator determines that the
              application of the inconsistent Arbitration Agreement terms would
              not result in a fundamentally fair arbitration. To learn more
              about arbitration through the AAA, visit{" "}
              <a
                href="http://www.adr.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.adr.org
              </a>
              .
            </li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          Prior to initiating arbitration, you and we agree to first make
          reasonable efforts to try to resolve a Claim amicably through
          good-faith negotiations. If a Claim cannot be resolved amicably within
          60 days after a party gives written notice (email sufficient) of the
          Claim to the other, then either party may initiate arbitration in
          accordance with this Arbitration Agreement.
        </Typography>
        <Typography variant="body1margin">
          To initiate arbitration, a party must provide the other party with a
          written Demand for Arbitration as specified in the AAA Rules. If you
          initiate arbitration, please send your written Demand for Arbitration
          to:
        </Typography>
        <Typography variant="body1margin">
          TurningPoint Healthcare Solutions LLC
        </Typography>
        <Typography variant="body1margin">Attn: Privacy Officer</Typography>
        <Typography variant="body1margin">
          1000 Primera Blvd., Suite 3160
        </Typography>
        <Typography variant="body1margin">Lake Mary, FL. 32746</Typography>
        <Typography variant="body1margin">
          If we initiate arbitration, we will send our written Demand for
          Arbitration to you at the email or mailing address you have provided
          to us.
        </Typography>
        <Typography variant="body1margin">
          In arbitration, the arbitrator will: (i) follow these Terms and apply
          the governing law set forth in the “Governing Law” section of these
          Terms, consistent with the United States Federal Arbitration Act and
          applicable statutes of limitations (or, to the extent (if any) that
          federal law prevails, will apply the applicable federal laws of the
          United States, irrespective of any conflict of law principles); (ii)
          entertain any motion to dismiss, motion to strike, motion for judgment
          on the pleadings, motion for complete or partial summary judgment,
          motion for summary adjudication or any other dispositive motion
          consistent with the governing law set forth in the “Governing Law”
          section of these Terms or U.S. federal rules of procedure, as
          applicable; (iii) honor claims of privilege recognized at law; and
          (iv) have authority to award any form of legal or equitable relief
          consistent with applicable laws (except that the arbitrator may not
          award any punitive, incidental, indirect, special, or consequential
          damages). The decision of the arbitrator will be final and binding on
          both parties. Judgment on the arbitration award rendered may be
          entered in any court of competent jurisdiction.
        </Typography>
        <Typography variant="body1margin">
          <ul>
            <li>
              The arbitration shall be held in the county in which you reside or
              at another mutually agreed location. If the value of the relief
              sought is $10,000 or less, you or TurningPoint may elect to have
              the arbitration conducted by telephone or based solely on written
              submissions, which election shall be binding on each party, but
              subject to the arbitrator's discretion to require an in-person
              hearing if the circumstances warrant. Attendance at any in-person
              hearing may be made by telephone by either or both parties unless
              the arbitrator requires otherwise.
            </li>
            <li>
              <b>CLASS ACTION WAIVER.</b> YOU AND TURNINGPOINT AGREE THAT EACH
              PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
              BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
              CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND
              TURNINGPOINT AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE
              OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS AND MAY NOT
              OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE,
              OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
              (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN
              FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE
              EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S
              INDIVIDUAL CLAIM(S).
            </li>
            <li>
              You and TurningPoint agree that the entire arbitration proceeding
              will be conducted in a confidential manner and that the
              arbitrator's decision and award will be kept confidential by both
              parties and, except as may be required by applicable law or a
              valid court order, will not be disclosed to any third party
              without the express prior written consent of both parties.
            </li>
            <li>
              <b>Costs of Arbitration.</b> Payment of all filing,
              administration, and arbitrator fees (collectively, the “
              <b>Arbitration Fees</b>”) will be governed by the AAA Rules. Each
              party will be responsible for all other fees it incurs in
              connection with the arbitration, including without limitation, all
              attorney fees.
            </li>
            <li>
              If a court decides that any term or provision of this Arbitration
              Agreement (other than Section 20 of these Terms) is invalid or
              unenforceable, the parties agree to replace such term or provision
              with a term or provision that is valid and enforceable and that
              comes closest to expressing the intention of the invalid or
              unenforceable term or provision, and this Arbitration Agreement
              shall be enforceable as so modified.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1margin">
          <b>15. CLASS ACTION AND JURY TRIAL WAIVER.</b> IF FOR ANY REASON A
          CLAIM ARISING OUT OF OR RELATING TO YOUR USE OF OR ACCESS TO THE
          SERVICES OR THESE TERMS IN ANY WAY PROCEEDS IN COURT RATHER THAN IN
          ARBITRATION, REGARDLESS OF WHETHER THE CLAIM IS AN ACTION,
          COUNTERCLAIM, OR ANY OTHER COURT PROCEEDING, BOTH PARTIES AGREE THAT
          TO THE EXTENT ALLOWED BY LAW, THERE WILL NOT BE A JURY TRIAL OR CLASS
          ACTION AND WE EACH UNCONDITIONALLY (1) WAIVE ANY RIGHT TO TRIAL BY
          JURY AND (2) WAIVE ANY RIGHT TO PURSUE DISPUTES ON A CLASS-WIDE BASIS,
          INCLUDING JOINING A CLAIM WITH THE CLAIM OF ANY OTHER PERSON OR ENTITY
          OR ASSERTING A CLAIM IN A REPRESENTATIVE CAPACITY ON BEHALF OF ANYONE
          ELSE IN ANY OTHER PROCEEDING.
        </Typography>
        <Typography variant="body1margin">
          <b>16. LIMITATION ON TIME TO FILE CLAIMS.</b> YOU AGREE THAT ANY CAUSE
          OF ACTION OR CLAIM YOU MAY HAVE AGAINST US ARISING OUT OF OR RELATED
          TO THESE TERMS OR THE SERVICES OR ANY CONTENT OR OTHER ITEMS PROVIDED
          THROUGH THE SERVICES MUST BE BROUGHT WITHIN ONE (1) YEAR AFTER THE
          CAUSE OF ACTION OR CLAIM ACCRUES, OR BE PERMANENTLY BARRED.
        </Typography>
        <Typography variant="body1margin">
          <b>17. Indemnification.</b> You agree to defend, indemnify and hold
          harmless TurningPoint and anyone associated with TurningPoint from and
          against any and all claims, liabilities, demands, losses, damages,
          fees, or penalties, and the cost to investigate and defend against
          them (including reasonable attorneys' fees) arising out of or relating
          to (1) your access to or use of, or activities in connection with, the
          Services (including any content in any format you make available
          through the Services), (2) your breach or alleged breach of these
          Terms, or (3) any violation or alleged violation of any applicable law
          by you or anyone accessing the Services on your behalf. TurningPoint
          reserves the right, at our expense, to assume exclusive defense and
          control of any matter otherwise subject to indemnification by you and,
          in any case, you agree to cooperate with us if and as requested by us
          in the defense and settlement of such matter.
        </Typography>
        <Typography variant="body1margin">
          <b>18. Injunctive Relief.</b> You hereby acknowledge that your breach
          of these Terms may result in immediate and irreparable harm to
          TurningPoint. Accordingly, you hereby agree that, in the event of such
          a breach by you, TurningPoint shall be entitled to equitable relief to
          compel you to cease and desist all unauthorized use, evaluation and/or
          disclosure of the Services, or Content or other information obtained
          through the of the Services, as well as any and all other remedies
          available at law or in equity.
        </Typography>
        <Typography variant="body1margin">
          <b>19. Note to International Users.</b> The Services are controlled by
          TurningPoint, which is located in the United States of America. We
          make no representation that the Services or Content are appropriate or
          available for use in locations outside of the United States. Accessing
          the Services from territories where such content, information or other
          materials are illegal is prohibited. Those who choose to access these
          Services from locations outside of the United States do so on their
          own initiative and are responsible for compliance with local laws.
        </Typography>
        <Typography variant="body1margin">
          <b>20. General Terms.</b>
        </Typography>
        <Typography variant="body1margin">
          <ul>
            <li>
              <b>Governing Law.</b> This Terms is governed by the laws of the
              State of Florida, without respect to its conflict of laws
              provisions, except that the United States Federal Arbitration Act
              shall govern the interpretation and enforcement of the Arbitration
              Agreement contained in Section 16 The United Nations Convention on
              Contracts for the International Sale of Goods shall not apply to
              these Terms.
            </li>
            <li>
              <b>Severability.</b> If any provision of these Terms is found to
              be unlawful, invalid or unenforceable by any court having
              competent jurisdiction, the invalidity or unenforceability of such
              provision shall not affect the validity of the remaining
              provisions of these Terms, which shall remain in full force and
              effect.
            </li>
            <li>
              <b>No Waiver.</b>
              Failure of TurningPoint to act on or enforce any provision of
              these Terms shall not be construed as a waiver of that provision
              or any other provision in these Terms. No waiver shall be
              effective against TurningPoint unless made in writing, and no such
              waiver shall be construed as a waiver in any other or subsequent
              instance.
            </li>
            <li>
              <b>Force Majeure.</b> We will not be liable or responsible to you,
              nor be deemed to have defaulted or breached these Terms, for any
              failure or delay in our performance under these Terms when and to
              the extent such failure or delay is caused by or results from acts
              or circumstances beyond our reasonable control, including, without
              limitation, acts of God, flood, fire, earthquake, explosion,
              governmental actions, war, invasion or hostilities (whether war is
              declared or not), terrorist threats or acts, riot or other civil
              unrest, national emergency, revolution, insurrection, epidemic,
              pandemic, lockouts, strikes or other labor disputes (whether or
              not relating to our workforce), or restraints or delays affecting
              carriers or inability or delay in obtaining supplies of adequate
              or suitable materials, materials or telecommunication breakdown or
              power outage.
            </li>
            <li>
              <b>No Third-Party Beneficiaries.</b> Except as expressly set forth
              herein, these Terms do not and are not intended to confer any
              rights or remedies upon any person other than you.
            </li>
            <li>
              <b>Notice; Electronic Notices.</b> You must provide us with notice
              by writing to us at our registered address. You agree that, where
              applicable, we may send you notice via email to the email address
              you have provided, and we are not responsible for your failure to
              receive notice if email is quarantined by your email security
              system (e.g., “junk” or “spam” folder) or if you fail to update
              your email address. You also agree that we may send you notice
              through the Services.
            </li>
            <li>
              TurningPoint may assign its ownership, control, or other rights to
              any party at any time without notice to you. These Terms will
              inure to the benefit of our successors, assigns, licensees, and
              sublicensees.
            </li>
            <li>
              <b>Section Headings.</b> The section headings are provided merely
              for convenience and shall not be given any legal import.
            </li>
            <li>
              <b>No Support.</b> We are under no obligation to provide support
              for the Services.
            </li>
            <li>
              <b>Notice Regarding Apple.</b> This section only applies to the
              extent you are using a TurningPoint App on an iOS device. You
              acknowledge that these Terms are between you and TurningPoint
              only, not with Apple Inc. (“Apple”), and Apple is not responsible
              for the Services or the Content or any other content thereof.
              Apple has no obligation to furnish any maintenance and support
              services with respect to the Services. If the Services fail to
              conform to any applicable warranty, you may notify Apple and Apple
              will refund any applicable purchase price for the mobile
              application to you; and, to the maximum extent permitted by
              applicable law, Apple has no other warranty obligation with
              respect to the Services. Apple is not responsible for addressing
              any claims by you or any third party relating to the Services or
              your possession and/or use of the Services, including: (a) product
              liability claims; (b) any claim that the Services fail to conform
              to any applicable legal or regulatory requirement; or (c) claims
              arising under consumer protection or similar legislation. Apple is
              not responsible for the investigation, defense, settlement, and
              discharge of any third-party claim that the Services and/or your
              possession and use of the Services infringe a third party's
              intellectual property rights. You agree to comply with any
              applicable third-party terms when using the Services. Apple and
              Apple's subsidiaries are third-party beneficiaries of these Terms,
              and upon your acceptance of these Terms, Apple will have the right
              (and will be deemed to have accepted the right) to enforce these
              Terms against you as a third-party beneficiary of these Terms. You
              hereby represent and warrant that: (i) you are not located in a
              country that is subject to a U.S. Government embargo, or that has
              been designated by the U.S. Government as a “terrorist supporting”
              country; and (ii) you are not listed on any U.S. Government list
              of prohibited or restricted parties.
            </li>
            <li>
              <b>Contact.</b> The Services are offered by TurningPoint
              Healthcare Solutions LLC, located at 1000 Primera Blvd, Suite 3160
              Lake Mary, FL. 32746. You may contact us by sending correspondence
              to that address or at any of our numbers or email addresses
              displayed through the Services.
            </li>
            <li>
              <b>Entire Agreement.</b> These Terms constitute the entire
              agreement between you and TurningPoint with respect to the subject
              matter of these Terms and supersede all previous or
              contemporaneous agreements, whether written or oral, between the
              parties with respect to the subject matter. A printed version of
              these Terms and of any related notice given in electronic form
              shall be admissible in arbitral, judicial or administrative
              proceedings based upon or relating to these Terms to the same
              extent and subject to the same conditions as other business
              documents and records originally generated and maintained in
              printed form.
            </li>
          </ul>
        </Typography>
      </Box>
    </>
  );
};

export default TOSPage;
