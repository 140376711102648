import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import Image from "../../../components/Image/Image";
import Line from "../../../components/Line/Line";
import Disclaimer from "../Disclaimer/Disclaimer";

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = (evt) => {
    navigate("/");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <footer>
      <Box bottom={0} left={0} width={1} bgcolor="background.primary">
        <Disclaimer />
        <Line />
        <Grid mx={[3, 16]} my={2}>
          <Button
            onClick={handleClick}
            sx={{
              ":hover": {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
              },
              ":focus": {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
              },
            }}
          >
            <Image
              logo
              width="auto"
              height={50}
              src="/static/img/logo.svg"
              alt="TurningPoint logo"
            />
          </Button>
        </Grid>
      </Box>
    </footer>
  );
};

export default Footer;
