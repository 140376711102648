import { Grid, Typography } from "@mui/material";
import AnchorBox from "../../../components/AnchorBox/AnchorBox";

const ProcedureBoxes = ({ items = [], title, isGeneral = false }) => {
  title =
    title ?? isGeneral
      ? "Learn About a Category"
      : "Learn About Your Procedure";
  return (
    <Grid container direction="column" gap={[1, 2]}>
      <Grid item>
        <Typography variant="h3" fontWeight={400}>
          {title}
        </Typography>
      </Grid>
      <Grid item display="flex">
        <Grid container spacing={2}>
          {items.map((item) => (
            <AnchorBox key={item} item={item} size={items.length} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProcedureBoxes;
