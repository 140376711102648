import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import ProcedureBox from "../ProcedureBox/ProcedureBox";
import Image from "../Image/Image";

import { procedureItems, procedurePages } from "../../data/procedures";
import BlueButton from "../Basic/BlueButton/BlueButton";
import WhiteButton from "../Basic/WhiteButton/WhiteButton";

const ProcedureModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  const [selectedProcedure, setSelectedProcedure] = useState(null);

  const handleMemberSubmit = (event) => {
    event.preventDefault();


    const procedureItem = procedureItems?.[selectedProcedure];
    if (procedureItem && procedurePages?.[procedureItem?.slug]) {
      setSelectedProcedure(null);
      onClose();
      navigate(`/procedures/${procedureItem.slug}`);
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      PaperProps={{ component: "form" }}
    >
      <DialogTitle>
        <Box
          px={2}
          display="flex"
          alignItems={["flex-start", "center"]}
          flexDirection={["column", "row"]}
          justifyContent="space-between"
        >
          <Image
            logo
            width="auto"
            height={50}
            src="/static/img/logo.svg"
            alt="TurningPoint logo"
          />
          <Typography fontSize={24} fontWeight={400} color="text.dark">
            Education Library
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box textAlign="center">
          <DialogContentText color="text.primary">
            Choose a different topic?
          </DialogContentText>

          <DialogContent sx={{ p: "10px", my: 2 }}>
            <Grid
              container
              spacing={2}
              justifyContent={["flex-start", "space-evenly"]}
            >
              {procedureItems.map((item, idx) => (
                <ProcedureBox
                  item={item}
                  key={item.label}
                  size={procedureItems.length}
                  isSelected={selectedProcedure === idx}
                  onSelect={() => setSelectedProcedure(idx)}
                />
              ))}
            </Grid>
          </DialogContent>

          <WhiteButton
            type="submit"
            onClick={handleClose}
            style={{
              margin: "0 30px 23px",
              width: 180,
              padding: "8px 50px",
            }}
          >
            Cancel
          </WhiteButton>

          <BlueButton
            type="submit"
            disabled={selectedProcedure === null}
            style={{
              margin: "0 30px 23px",
              width: 180,
              padding: "8px 50px",
            }}
            onClick={handleMemberSubmit}
          >
            Yes
          </BlueButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProcedureModal;
