export const titleCase = (text) =>
	text.replace(/\b\w/g, (letter) => letter.toUpperCase());

export const sentenceCase = (text) =>
	text.replace(/\b\w/, (letter) => letter.toUpperCase());

export const toUrl = (text = "") =>
	text.toLocaleLowerCase().split(" ").join("-");

export const fromUrl = (text = "") => text.replaceAll("-", " ");
