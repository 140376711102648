import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  height: "5rem",
  textAlign: "center",
  borderRadius: 6,
  background: "#FFF",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
  ":focus": { boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.5)" },
  ":hover": { boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.5)" },
}));
