import { SvgIcon } from "@mui/material";

export const RightArrowIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
      >
        <g clipPath="url(#clip0_390_1580)">
          <path
            d="M1 11.4994H21.287M21.287 11.4994L12.012 2.21045M21.287 11.4994L12.012 20.7894"
            stroke="#0052B1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_390_1580">
            <rect
              width="22.287"
              height="21.407"
              fill="white"
              transform="translate(0 0.796509)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
