import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const CategorySection = styled(Grid, {
	shouldForwardProp: (prop) => prop !== "darkBg",
})(({ theme, ...props }) => ({
	backgroundColor: props.darkBg
		? theme.palette.background.secondary
		: theme.palette.background.primary,
}));
