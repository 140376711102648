import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const StyledArticleBox = styled(Grid)(({ theme }) => ({
	display: "flex",
	width: "100%",
	flexDirection: "column",
	justifyContent: "space-between",
	padding: 10,
	borderRadius: 8,
	background: "#FFF",
	boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
}));
