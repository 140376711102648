import useToken from "../hooks/useToken";
import MemberModal from "../components/MemberModal/MemberModal";
import Banner from "./components/Banner/Banner";
import { useEffect } from "react";
import log from "../api/log";

const HomePage = () => {
  const { setToken } = useToken();

  useEffect(() => {
    log.pageView("information", {message: "Home Page View", success: true, url: window.location.href})
  }, [])

  return (
    <>
      <Banner height={346} />
      <MemberModal setToken={setToken} />
    </>
  );
};

export default HomePage;
