import { Button } from "@mui/material";

const WhiteButton = ({ onClick, children, ...props }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      sx={{
        borderRadius: 100,
        padding: "8px 20px 8px 20px",
        "&:hover": {
          //   bgcolor: "primary.main",
          //   color: "#FFF",
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
        },
        "&:focus": {
          //   bgcolor: "primary.main",
          //   color: "#FFF",
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);",
        },
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export default WhiteButton;
