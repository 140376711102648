import { Button } from "@mui/material";

const BlueButton = ({ onClick, children, ...props }) => {
  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: 100,
        padding: "8px 20px 8px 20px",
        color: "#FFF",
        "&:hover": {
          bgcolor: "background.secondary",
          color: "primary.main",
        },
        "&:focus": {
          bgcolor: "background.secondary",
          color: "primary.main",
        },
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BlueButton;
