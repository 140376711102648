import log from "../api/log";

export const setWithExpiry = (key, value, expirySeconds) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + expirySeconds * 1000, // Convert seconds to milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const extendExpiry = (key, expirySeconds) => {
  const value = getWithExpiry(key);
  if (value) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expirySeconds * 1000, // Convert seconds to milliseconds
    };
    localStorage.setItem(key, JSON.stringify(item));
    log.session("information", {message: "Session extended", success: true})
  }
};